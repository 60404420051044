import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useModalContext } from '@/components/modal/ModalContext';
import { IconButton } from '@instech/components';
import { Remove } from '@instech/icons';

export const Content = styled.div`
  align-content: center;
  text-align: center;
`;

export const Header = styled.div`
  color: ${props => props.theme.marineBlue};
  font-size: 16px;
  font-weight: bold;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-evenly;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 22px 22px 0px 0px;
`;

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: 50px;
`;

export const ModalLayout: FunctionComponent = ({ children }) => {
  const { close } = useModalContext();
  return (
    <div>
      <TopRow>
        <IconButton
          icon={<Remove />}
          onClick={close}
        />
      </TopRow>
      <ContentLayout>
        {children}
      </ContentLayout>
    </div>
  );
};

interface ModalProps { close: () => any; }

export const ModalLayoutWithClose: FunctionComponent<ModalProps> = ({ children, close }) => (
  <div>
    <TopRow>
      <IconButton
          icon={<Remove />}
          onClick={close}
      />
    </TopRow>
    <ContentLayout>
      {children}
    </ContentLayout>
  </div>
);
