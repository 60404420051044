import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@instech/components';
import { Edit } from '@instech/icons';

const TitleWrapper = styled.div<{ padding?: string }>`
  display: flex;
  align-items: center;
  color: #003A4D;
  font-size: 18px;
  font-weight: bold;
  padding: ${props => props.padding};
`;

const EditButton = styled(IconButton)`
  margin-left: 6px;
  & svg {
    color: ${props => props.theme.marineBlue75};
    width: 16px;
  }
`;

interface Props {
  title: string;
  onEdit: () => void;
  padding?: string;
}
export const TitleAndEdit = ({ title, onEdit, padding = '12px 0px' }: Props) => (
  <TitleWrapper padding={padding}>
    {title}
    <EditButton icon={<Edit />} height="16px" width="16px" smallIcon onClick={onEdit} />
  </TitleWrapper>
);
