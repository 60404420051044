import React, { FunctionComponent, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps {
  width?: string;
  height?: string;
  small?: boolean;
  transparent?: boolean;
}
const StyledButton = styled.button<ButtonProps>`
  box-sizing: border-box;
  border: none;
  background-color: 'transparent';
  cursor: pointer;
  width: ${props => props.width};
  height: ${props => props.height};
  display: flex;
  align-items: center;
  justify-content: center;

  // If the icon is not transparent, use marine blue as go-to
  ${props => !props.transparent && css`
    background-color: ${props.theme.marineBlue};
    color: ${props.theme.white};
  `}

  & svg {
    flex-shrink: 0;
    width: ${props => props.small ? '18px' : '24px'};
  }
  &:disabled {
    cursor: default;
  } 
`;

interface Props {
  type?: 'button' | 'submit' | 'reset';
  icon: JSX.Element;
  smallIcon?: boolean;
  width?: string;
  height?: string;
  transparent?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  title?: string;
  ariaLabel?: string;
  className?: string;
}
export const IconButton: FunctionComponent<Props> = ({
  type = 'button',
  icon,
  smallIcon,
  width = '40px',
  height = '40px',
  transparent = false,
  onClick,
  disabled = false,
  title,
  ariaLabel,
  className
}) => (
  <StyledButton
    type={type}
    small={smallIcon}
    width={width}
    height={height}
    transparent={transparent}
    onClick={onClick}
    disabled={disabled}
    title={title}
    aria-label={ariaLabel}
    className={className}
  >
    {icon}
  </StyledButton>
);
