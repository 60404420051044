import React from 'react';
import styled from 'styled-components';
import { ProgressBar } from '@/components/shared/ProgressBar';

const Container = styled.div`
  margin-bottom: 1.5rem;
  margin-top: 1rem;
`;

interface Props {
  /** 0-indexed step number */
  steps: {
    step: number;
    name: string;
  }[];
  currentStep: number;
}
export const NewAnnouncementProgress = ({ steps, currentStep }: Props) => (
  <Container>
    <ProgressBar steps={steps} step={currentStep} />
  </Container>
);
