import React, {
  FunctionComponent,
  useEffect,
  useState
} from 'react';
import {
  Redirect,
  Route,
  Switch
} from 'react-router';
import { ProtectedRoute } from '@/authentication/routing/ProtectedRoute';
import { UnauthorizedPage } from '../pages/UnauthorizedPage';
import { CallbackPage } from '../pages/CallbackPage';
import { LoginPage } from '../pages/LoginPage';

const ClearRolePage = () => {
  const [reload, setReload] = useState(false);
  useEffect(() => {
    localStorage.removeItem('role');
    setReload(true);
  }, []);
  return reload ? <Redirect push exact to="/" /> : <div>Clearing Role</div>;
};

export const RootRouting: FunctionComponent = ({ children }) => (
  <Switch>
    <Route path="/login">
      <LoginPage />
    </Route>
    <Route path="/callback">
      <CallbackPage />
    </Route>
    <Route path="/unauthorized">
      <UnauthorizedPage />
    </Route>
    <Route path="/signedout">
      <LoginPage />
    </Route>
    <Route exact path="/clear-role">
      <ClearRolePage />
    </Route>
    <ProtectedRoute path="/">
      {children}
    </ProtectedRoute>
  </Switch>
);
