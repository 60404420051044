import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AuthenticationProvider } from 'its-js-authentication';
import { PromptOnUnsavedChanges } from '@instech/components';
import { SWRConfig } from 'swr';
import { ModalHandler } from './components/modal/Modal';
import { App } from './App';
import { unregister } from './registerServiceWorker';
import { theme } from './utils/theme';
import 'normalize.css';
import './fonts.scss';
import './custom.css';
import { userManager } from './utils/userManager';
import { getJsonAsync } from './services/client';
import { FeatureFlagProvider } from './providers/featureFlagProvider';
import { ErrorBoundary } from './components/error/ErrorBoundary';

const swrConfig = {
  revalidateOnFocus: false,
  errorRetryCount: 3,
  fetcher: (...args) => getJsonAsync(...args)
};

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <ErrorBoundary>
    <AuthenticationProvider userManager={userManager}>
      <BrowserRouter basename={baseUrl}>
        <ThemeProvider theme={theme}>
          <SWRConfig value={swrConfig}>
            <PromptOnUnsavedChanges>
              <FeatureFlagProvider>
                <ModalHandler>
                  <App />
                </ModalHandler>
              </FeatureFlagProvider>
            </PromptOnUnsavedChanges>
          </SWRConfig>
        </ThemeProvider>
      </BrowserRouter>
    </AuthenticationProvider>
  </ErrorBoundary>,
  rootElement
);

unregister();
