import React, {
  FunctionComponent,
  useEffect,
  useState
} from 'react';
import { Redirect } from 'react-router-dom';
import { useAccount } from 'its-js-authentication';

// Note, the disabled eslint is correct, signinCallback is not an updateable dependency in our effect
const CallbackComponent: FunctionComponent = ({ children }) => {
  const { signinCallback } = useAccount();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    void signinCallback().then(state =>
      setUrl(state.route || '/'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (url) {
    return <Redirect to={url} />;
  }

  return (
    <>{children}</>
  );
};

export const CallbackPage = () => (
  <CallbackComponent>
    <div>Redirecting...</div>
  </CallbackComponent>
);
