import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { BoxButton, ButtonProps } from '../BoxButton';
import { BoxButtonSlim } from '../BoxButtonSlim';

interface NavButtonProps extends ButtonProps {
  to: string;
}

export const NavButton: FunctionComponent<NavButtonProps> = Props => (
  <Link to={Props.to}>
    <BoxButton {...Props}>
      {Props.children}
    </BoxButton>
  </Link>
);

export const NavButtonSlim: FunctionComponent<NavButtonProps> = Props => (
  <Link to={Props.to}>
    <BoxButtonSlim {...Props}>
      {Props.children}
    </BoxButtonSlim>
  </Link>
);
