import { ProgressBar } from '@/components/shared/ProgressBar';
import React from 'react';
import styled from 'styled-components';

const steps = [
  {
    step: 1,
    name: 'Select company'
  },
  {
    step: 2,
    name: 'Add portal'
  },
  {
    step: 3,
    name: 'Add users'
  }
];

const Wrapper = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  flex: 1;
`;

interface Props {
  /** 0-indexed step number */
  currentStep: number;
}

export const AddCompanyProgress = (props: Props) => (
  <Wrapper>
    <ProgressBar steps={steps} step={props.currentStep} />
  </Wrapper>
);
