import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  background-color: ${props => props.theme.lightBlue};
`;

const Title = styled.span`
  display: block; 
  border-bottom: 1px solid ${props => props.theme.marineBlue};
  padding: 10px 20px;
  color: ${props => props.theme.marineBlue};
`;

const Content = styled.div`
  padding: 20px;
`;

interface SidebarProps {
  title: string;
}
export const Sidebar: FunctionComponent<SidebarProps> = ({ children, title }) => (
  <Layout>
    <Title>{title}</Title>
    <Content>
      {children}
    </Content>
  </Layout>
);

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 358px;
`;
