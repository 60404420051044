import React, { FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { Loader } from '@/components/shared/Loader';
import {
  CellButton,
  CellButtonChevron,
  Table, TableCell, TableHeader, TableRow
} from '@/components/shared/Table';
import { AddCompanyProgress } from '@/enrollment/core/AddCompanyProgress';
import { NextButton } from '@/enrollment/core/NextButton';
import { TopSection } from '@/enrollment/core/TopSection';
import { useCompanyContacts } from '@/enrollment/services/services';
import { useCompanyByExternalId } from '@/services/companiesService';
import { ExternalCompanyContact } from '@/types';
import { Heading2 } from '@/components/shared/Headings/Heading2';
import { CompanyInfo } from '@/components/shared/CompanyInfo/CompanyInfo';
import { useModalContext } from '@/components/modal/ModalContext';
import { openManageContactAccessModal } from './ManageAccessModal/ManageContactAccessModal';

const HeaderRow = () => (
  <>
    <TableHeader>Name</TableHeader>
    <TableHeader>Title</TableHeader>
    <TableHeader>Email</TableHeader>
    <TableHeader>Phone</TableHeader>
    <TableHeader>Status</TableHeader>
    <TableHeader>Manage access</TableHeader>
  </>
);

const ManageAccessCell = styled(TableCell)`
  padding-right: 12px;
  justify-content: flex-end;
`;

interface RowProps {
  contact: ExternalCompanyContact;
  companyId: number;
  even: boolean;
}
export const Row: FunctionComponent<RowProps> = ({ contact, even, companyId }) => {
  const { open } = useModalContext();
  const showAccessModal = openManageContactAccessModal({ contact, companyId });

  // email is required to add the user to Etuity - users without email are disabled
  const isDisabled = !contact.email || !contact.active;

  return (
    <TableRow even={even}>
      <TableCell>{contact.name}</TableCell>
      <TableCell>{contact.title}</TableCell>
      <TableCell>{contact.email}</TableCell>
      <TableCell>{contact.phoneMobile || contact.phoneDirect}</TableCell>
      <TableCell>{contact.active ? 'Active' : 'Passive'}</TableCell>
      <ManageAccessCell>
        <CellButton
          ariaLabel="Manage access"
          disabled={isDisabled}
          icon={<CellButtonChevron right thin disabled={isDisabled} />}
          onClick={!isDisabled ? () => open(showAccessModal) : undefined}
        />
      </ManageAccessCell>
    </TableRow>
  );
};

const NextPageButton = () => {
  const { companyId } = useParams() as any;
  const history = useHistory();
  const route = `/companies/${companyId}`;
  return (
    <NextButton submit={() => history.push(route)} text="Company overview >" />
  );
};

export const AddUsersStep = () => {
  const { companyId } = useParams() as any;

  const { data: company, error: companyError } = useCompanyByExternalId(companyId);
  const { data: contacts, error: contactsError } = useCompanyContacts(company?.companyNumber);

  if (companyError || contactsError) {
    throw new Error(companyError || contactsError);
  }

  if (!company || !contacts) {
    return <Loader />;
  }

  return (
    <div>
      <TopSection>
        <AddCompanyProgress currentStep={2} />
        <NextPageButton />
      </TopSection>
      <Heading2>{company.name}</Heading2>
      <CompanyInfo company={company} />
      <Heading2>Contacts</Heading2>
      <Table gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 125px">
        <HeaderRow />
        {contacts.map((contact, i) => (
          <Row
            key={contact.id}
            contact={contact}
            companyId={company.id}
            even={i % 2 === 0}
          />
        )) }
      </Table>
    </div>
  );
};
