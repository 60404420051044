import styled, { css } from 'styled-components';

interface BorderProps {
  readonly width?: string;
  readonly height?: string;
  readonly darkBorder?: boolean;
  readonly noBorder?: boolean;
  readonly disabled?: boolean;
  readonly isSelected?: boolean;
}
export const ImageBorder = styled.div<BorderProps>`
  position: relative;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  border: 1px solid ${props => props.darkBorder ? props.theme.marineBlue : props.theme.border.gray};
  box-sizing: border-box;
  ${props => props.noBorder && css`
    border: 0px;
  `}
  ${props => props.onClick && css`
    cursor: ${props.disabled ? 'initial' : 'pointer'};
  `}
  ${props => props.isSelected && css`
    border-color: ${props.theme.marineBlue};
    &:after {
      content: '';
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      border: 4px solid ${props.theme.border.brightBlue};
    }
  `}
`;
