import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Add } from '@instech/icons';
import { useCompanies } from '@/services/companiesService';
import { Company } from '@/types';
import { PageContent } from '@/components/layout/Page';
import { Loader } from '../../shared/Loader';
import {
  Table, TableHeader, TableCell, TableRow
} from '../../shared/Table';
import { BoxButton } from '../../shared/BoxButton/BoxButton';
import { BusinessAccounts } from './BusinessAccounts';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
  margin: 30px 0px;
`;

const RowLink = styled(Link)`
  display: contents;
`;

const AddCompany = () => (
  <Link to="/add-company">
    <BoxButton
      startIcon={<Add />}
      fontSize="14px"
      padding="10px 20px 10px 10px"
      boldFont
    >
      Add company
    </BoxButton>
  </Link>
);

const HeaderRow = () => (
  <>
    <TableHeader>Company Name</TableHeader>
    <TableHeader>Type</TableHeader>
    <TableHeader>CRM Number</TableHeader>
    <TableHeader>Bus.Acc</TableHeader>
    <TableHeader>City</TableHeader>
    <TableHeader>Country</TableHeader>
    <TableHeader>Email</TableHeader>
  </>
);

interface RowProps { company: Company; even: boolean; }
const Row: FunctionComponent<RowProps> = ({ company, even }) => (
  <RowLink to={`/companies/${company.mdmExternalId}`}>
    <TableRow even={even}>
      <TableCell>{company.name}</TableCell>
      <TableCell>{company.type}</TableCell>
      <TableCell>{company.companyNumber}</TableCell>
      <BusinessAccounts accounts={company.businessAccounts} />
      <TableCell>{company.city}</TableCell>
      <TableCell>{company.country}</TableCell>
      <TableCell>{company.email}</TableCell>
    </TableRow>
  </RowLink>
);

const CompanyRows = () => {
  const { data: companies } = useCompanies();

  if (!companies) return <Loader />;
  return (
    <>
      {companies.map((company, i) => <Row key={company.id} company={company} even={i % 2 === 0} />)}
    </>
  );
};

export const CompaniesPage = () => (
  <PageContent>
    <Wrapper>
      <Title>Company overview</Title>
      <AddCompany />
    </Wrapper>
    <Table columns={7}>
      <HeaderRow />
      <CompanyRows />
    </Table>
  </PageContent>
);
