import React from 'react';
import styled from 'styled-components';
import { WarningNotification } from '@instech/components';
import { ExternalUser } from '@/types';
import { ModalLayout } from '@/components/shared/ModalLayout';
import { EditUserForm } from './EditUserForm';

const warningHeading = 'Changing the Email will reset the users MFA';
const warningDescription = 'The user will have to re-enroll their MFA app';

const Warning = styled.div`
  margin-bottom: 20px;
`;

interface Props {
  user: ExternalUser;
}
const EditUserModal = (props: Props) => (
  <ModalLayout userName={props.user.name}>
    <Warning>
      <WarningNotification size="medium" headingText={warningHeading} descriptionText={warningDescription} />
    </Warning>
    <EditUserForm user={props.user} />
  </ModalLayout>
);

export const openEditUserModal = ({ user }: Props) => ({
  component: EditUserModal,
  options: {
    footer: true,
    size: 'medium',
    padding: 0
  },
  args: { user }
});
