import { NhcLogo, NhcLogoSymbol } from '@instech/icons';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Navigation } from './Navigation';
import { UserInfo } from './UserInfo';
import { useFeatureFlag } from '../../../providers/featureFlagProvider';
import { PortalSelectorLoader } from './PortalSelectorLoader';

const Row = styled.div<{scrolled: boolean}>`
  height: ${props => props.scrolled ? props.theme.header.scrolledHeight : props.theme.header.height};
  position: ${props => props.scrolled && 'sticky'};
  top: 0;
  z-index: 40;
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.marineBlue};
  color: #fff;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
  gap: 50px;
`;

const LogoText = styled.span`
  font-size: 20px;
  font-weight: bold;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif; 
`;

const StyledImage = styled.div<{scrolled: boolean}>`
  & svg {
    height: ${props => props.scrolled ? '32px' : '64px'};
    width: 130px;
  }
`;
const MenuSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const Logo = ({ scrolled = false }) => (
  <StyledImage scrolled={scrolled}>
    {scrolled ? <NhcLogoSymbol alt="NHC logo" /> : <NhcLogo alt="NHC logo" />}
  </StyledImage>
);

interface HeaderProps {
  scrolled: boolean,
}

export const Header: FunctionComponent<HeaderProps> = ({ scrolled = false }) => {
  const portalSelectorIsEnabled = useFeatureFlag('portalSelector');
  return (
    <Row scrolled={scrolled}>
      <LogoWrapper>
        <Logo scrolled={scrolled} />
        <LogoText>Onboarding</LogoText>
      </LogoWrapper>
      <Navigation />
      <MenuSection>
        <UserInfo />
        { portalSelectorIsEnabled && <PortalSelectorLoader /> }
      </MenuSection>
    </Row>
  );
};

export const HeaderWithoutNav: FunctionComponent<HeaderProps> = ({ scrolled = false }) => {
  const portalSelectorIsEnabled = useFeatureFlag('portalSelector');
  return (
    <Row scrolled={scrolled}>
      <LogoWrapper>
        <Logo scrolled={scrolled} />
        <LogoText>Onboarding</LogoText>
      </LogoWrapper>
      <MenuSection>
        <UserInfo />
        { portalSelectorIsEnabled && <PortalSelectorLoader /> }
      </MenuSection>
    </Row>
  );
};
