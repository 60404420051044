import React from 'react';
import styled from 'styled-components';
import { BoxButton } from '@/components/shared/BoxButton/BoxButton';

const ValidationMessage = styled.div`
  color: ${props => props.theme.red};
  position: absolute;
  top: 52px;
`;

const Wrapper = styled.div`
  margin-left: 24px;
  position: relative;
`;

const nextButtonText = 'Next  >';
interface NextButtonProps {
  text?: string;
  submit: () => Promise<void> | void;
  validationMessage?: string;
}
export const NextButton = ({ submit, validationMessage, text }: NextButtonProps) => (
  <Wrapper>
    <BoxButton onClick={submit}>{text || nextButtonText}</BoxButton>
    {validationMessage && <ValidationMessage>{validationMessage}</ValidationMessage>}
  </Wrapper>
);
