import client from '@/services/client';
import {
  Company,
  ExternalCompany,
  ExternalCompanyContact,
  ExternalCompanyType,
  Guid,
  Portal
} from '@/types';
import useSWR, { mutate } from 'swr';

export const useCompanyTypes = () => {
  const requestUrl = '/ExternalCompany/types';
  return useSWR<ExternalCompanyType[]>(requestUrl);
};

export const useCompanySearch = (type: String, name: String) => {
  const requestUrl = `/ExternalCompany/search?type="${type}"&name="${name}"`;
  return useSWR<ExternalCompany[]>(requestUrl);
};

export const usePortalApplications = (companyId: string) => {
  const requestUrl = `/CompanyApplication/${companyId}`;
  return useSWR<Portal[]>(requestUrl);
};

export const updateCompanyApplicationAsync = async (companyId: number, applicationId: number, selected: boolean): Promise<void> => {
  const requestUrl = `/CompanyApplication/${companyId}`;
  const request = {
    applicationId,
    selected
  };
  await client.put(requestUrl, request);
  await mutate(requestUrl);
};

export const useCompanyContacts = (companyNumber?: number) => {
  const requestUrl = companyNumber ? `ExternalCompany/${companyNumber}/contacts` : null;
  return useSWR<ExternalCompanyContact[]>(requestUrl);
};

export const useExternalCompany = (externalId: Guid) => {
  const requestUrl = externalId ? `ExternalCompany/${externalId}` : null;
  return useSWR<Company>(requestUrl);
};
