import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useModalContext } from '@/components/modal/ModalContext';
import { Heading2 } from '@/components/shared/Headings/Heading2';
import { IconButton } from '@instech/components';
import { Remove } from '@instech/icons';

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled(Heading2)`
  flex: 1;
  text-align: center;
`;

const Button = styled(IconButton)`
  margin-right: 8px;
`;

const Content = styled.div`
  padding: 16px;
`;

export const ModalLayout: FunctionComponent<{userName: string}> = ({ userName, children }) => {
  const { close } = useModalContext();
  return (
    <div>
      <TopRow>
        <Header>{userName}</Header>
        <Button
          icon={<Remove />}
          onClick={close}
        />
      </TopRow>
      <Content>
        {children}
      </Content>
    </div>
  );
};
