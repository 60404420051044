import React from 'react';
import { Loader } from '@/components/shared/Loader';
import { ExternalUser } from '@/types';
import { ModalLayout } from '@/components/shared/ModalLayout';
import { UserRoleSelect } from './UserRoleSelect';

interface Props {
  user: ExternalUser;
}

const ModalContent = ({ user }: Props) => {
  if (user === undefined) {
    return <Loader />;
  }
  return (
    <UserRoleSelect user={user} />
  );
};

export const ManageUserAccessModal = (props: Props) => (
  <ModalLayout userName={props.user.name}>
    <ModalContent {...props} />
  </ModalLayout>
);

export const openManageUserAccessModal = ({ user }: Props) => ({
  component: ManageUserAccessModal,
  options: {
    footer: true,
    size: 'medium',
    padding: 0
  },
  args: { user }
});
