import React from 'react';
import { useModalContext } from '@/components/modal/ModalContext';
import { Loader } from '@/components/shared/Loader';
import { useUser } from '@/services/usersService';
import { ExternalCompanyContact, ExternalUser } from '@/types';
import { companyTitle } from '@/utils/textutils';
import { ModalLayout } from '@/components/shared/ModalLayout';
import { PromptAddUser } from './PromptAddUser';
import { PromptAppendUser } from './PromptAppendUser';
import { UserRoleSelect } from './UserRoleSelect';

interface Props {
  contact: ExternalCompanyContact;
  companyId: number;
}

function userBelongsToCurrentCompany(user: ExternalUser, companyId: number) {
  if (user.companyNumericId === companyId) return true;
  if (!user.additionalCompanies) return false;
  const existingCompany = user.additionalCompanies.find(x => x.id === companyId);
  return !!existingCompany;
}

const ModalContent = ({ contact, companyId }: Props) => {
  const { data: user, error } = useUser(contact.email);
  const { close } = useModalContext();
  if (error) {
    throw new Error(error);
  }
  if (user === undefined) {
    return <Loader />;
  }
  if (user === null) {
    return (
      <PromptAddUser
        close={close}
        user={contact}
        companyId={companyId}
      />
    );
  }
  if (!userBelongsToCurrentCompany(user, companyId)) {
    const companyText = companyTitle(user);
    return (
      <PromptAppendUser close={close} user={contact} companyId={companyId} companyText={companyText} />
    );
  }
  return (
    <UserRoleSelect user={user} />
  );
};

export const ManageContactAccessModal = (props: Props) => (
  <ModalLayout userName={props.contact.name}>
    <ModalContent {...props} />
  </ModalLayout>
);

export const openManageContactAccessModal = ({ contact, companyId }: Props) => ({
  component: ManageContactAccessModal,
  options: {
    footer: true,
    size: 'medium',
    padding: 0
  },
  args: { contact, companyId }
});
