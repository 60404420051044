import React from 'react';
import styled, { css } from 'styled-components';
import Select, { ValueContainerProps } from 'react-select';
import { LabelValuePair } from '@/types';
import { SmallLoader } from '@/components/shared/Loader';

const FilterContainer = styled.div<{ loading?: boolean }>`
  width: 180px;
  height: 50px;
  border: 1px solid transparent;
  border-radius: 5px 0 0 5px;
  background: ${props => props.theme.ultraLightGray};
  // Just need this for the loader visual
  ${props => props.loading && css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const ValueWrapper = styled.div`
  font-size: 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  color: ${props => props.theme.marineBlue};
`;

const TypeWrapper = styled.div`
  margin-bottom: 2px;
`;

const TypeValue = styled.div`
  font-weight: bold;
  height: 18px;
`;

const ValueContainer = ({ children }: ValueContainerProps<LabelValuePair, false>) => (
  <ValueWrapper>
    <TypeWrapper>Type</TypeWrapper>
    <TypeValue>{children}</TypeValue>
  </ValueWrapper>
);

const SingleValue = ({ children }: any) => (
  <>{children}</>
);

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    height: '50px',
    border: 'none',
    borderRadius: '5px 0 0 5px',
    background: 'transparent',
    flexWrap: 'nowrap'
  }),
  placeholder: () => ({
    // Purge default Placeholder styling to instead use TypeValue style
    color: 'initial',
    opacity: '0.5'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  })
};

interface Props {
  options?: LabelValuePair[];
  selectedType?: string;
  onSelect: (newType?: string) => void;
}
export const SearchFilter = ({ options, selectedType, onSelect }: Props) => {
  if (!options) {
    return (
      <FilterContainer loading>
        <SmallLoader />
      </FilterContainer>
    );
  }

  const defaultOption = options.find(o => o.value === selectedType);

  return (
    <FilterContainer>
      <Select
        aria-label="Company type"
        styles={customStyles}
        options={options}
        defaultValue={defaultOption}
        onChange={e => onSelect(e?.value)}
        components={{ ValueContainer, SingleValue }}
        isSearchable={false}
      />
    </FilterContainer>
  );
};
