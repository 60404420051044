import React, { useState } from 'react';
import styled from 'styled-components';
import 'styled-components/macro';
import { BoxButton } from '@/components/shared/BoxButton/BoxButton';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { SmallLoader } from '@/components/shared/Loader';
import { addExternalUserAsync } from '@/services/usersService';
import { ExternalCompanyContact } from '@/types';
import { auth0Email } from '@/utils/validationSchemas';

const paragraph1Text = 'This user is currently not in the Etuity portal. By enrolling this user we will send them a login email.';
const paragraph2Text = 'Do you want to add this user?';

const Paragraph = styled.p`
  font-size: 18px;
  color: ${props => props.theme.marineBlue};
`;

const ValidationMessage = styled(Paragraph)`
  color: ${props => props.theme.red};
`;

interface Props {
  close: () => void;
  onAdd?: () => void;
  user: ExternalCompanyContact;
  companyId: number;
}

export const PromptAddUser = ({ close, onAdd, user, companyId }: Props) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [validationError, setValidationError] = useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = useState(false);
  if (error) {
    throw new Error(error);
  }

  const handleAdd = async () => {
    const validationResult = await auth0Email().validate(user.email).catch(e => e.message);

    if (validationResult !== user.email) {
      setValidationError(validationResult);
      return;
    }

    try {
      setSubmitting(true);
      await addExternalUserAsync(user, companyId);
      if (onAdd) {
        onAdd();
      }
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div>
      <Paragraph css="margin-top: 0;">{paragraph1Text}</Paragraph>
      <Paragraph css="margin-bottom: 32px;">{paragraph2Text}</Paragraph>
      {validationError && <ValidationMessage>{validationError}</ValidationMessage>}
      <ButtonGroup alignRight>
        <BoxButton inverted onClick={close}>Cancel</BoxButton>
        <BoxButton onClick={handleAdd} disabled={!!validationError}>
          {submitting ? <SmallLoader /> : 'Add'}
        </BoxButton>
      </ButtonGroup>
    </div>
  );
};
