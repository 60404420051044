import React, {
  useContext, useMemo, useState
} from 'react';
import { Announcement } from '@/types';

export interface AnnouncementStateContextType {
  updateState: (announcement: Announcement) => void,
  state: Announcement,
  dirty: boolean,
  setDirty: (val: boolean) => any;
}

const initialValues = {
  updateState: (announcement: Announcement) => { },
  state: {} as Announcement,
  dirty: false,
  setDirty: (val: boolean) => null,
};

export const AnnouncementStateContext = React.createContext<AnnouncementStateContextType>(initialValues);

export const AnnouncementStateProvider = ({ children }: { children?: React.ReactNode }) => {
  const [state, setState] = useState<Announcement>({} as Announcement);
  const [dirty, setDirty] = useState<boolean>(false);

  const value = useMemo(() => {
    const updateState = (newState: Announcement) => setState({ ...state, ...newState });
    return ({ updateState, state, dirty, setDirty });
  }, [state, dirty, setDirty]);

  return (
    <AnnouncementStateContext.Provider
      value={value}
    >
      {children}
    </AnnouncementStateContext.Provider>
  );
};

export const useAnnouncementState = () => useContext(AnnouncementStateContext);

export const getActualAnnouncementState = (initialAnnouncement: Announcement, currentAnnouncement: Announcement) =>
  Object.keys(currentAnnouncement).length > 0 ? currentAnnouncement : initialAnnouncement;
