import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { childrenPropType } from '../../../prop-types/custom-prop-types';

const Container = styled.div`
  color: ${props => props.theme[props.color] || props.theme.marineBlue};
  ${props => props.underline && css`
    text-decoration: underline;
  `}
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const StyledButton = styled.button`
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: ${props => props && props.width};
  height: ${props => props && props.height};
  &:disabled {
    cursor: default;
    > ${Container} {
      color: ${props => props.theme.placeholderGray};
    }
  }
`;

const TextContainer = styled.span`
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  // If there is an icon and a text that should both show (e.g. the text 
  // is not just for screen-readers), add margin between icon and button.
  ${props => props.StartIcon && !props.srTextOnly && css`
    margin-left: 10px;
  `}
`;

export const LinkButton = ({
  children,
  startIcon: StartIcon,
  endIcon: EndIcon,
  width,
  height,
  onClick,
  underline,
  color,
  srTextOnly, // The button text is only for screen-readers
  className,
  disabled,
  title
}) => (
  <StyledButton width={width} height={height} onClick={onClick} type="button" className={className} disabled={disabled} title={title}>
    <Container underline={underline} color={color}>
      {StartIcon}
      <TextContainer StartIcon={StartIcon} srTextOnly={srTextOnly}>{children}</TextContainer>
      {EndIcon}
    </Container>
  </StyledButton>
);

LinkButton.propTypes = {
  children: childrenPropType,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  underline: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  srTextOnly: PropTypes.bool,
  title: PropTypes.string
};
