import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Edit, FileParagraph, Trash
} from '@instech/icons';
import { LinkButton } from './LinkButton';
import { childrenPropType } from '../../../prop-types/custom-prop-types';

export const RemoveButton = ({ onClick }) => (
  <LinkButton startIcon={<Trash />} onClick={onClick}>Remove</LinkButton>
);

RemoveButton.propTypes = {
  onClick: PropTypes.func
};

// With text
export const EditButton = ({ onClick }) => (
  <LinkButton startIcon={<Edit />} onClick={onClick}>Edit</LinkButton>
);

EditButton.propTypes = {
  onClick: PropTypes.func
};

// Icon only
export const EditIconButton = ({ onClick }) => (
  <LinkButton startIcon={<Edit />} onClick={onClick} />
);

EditIconButton.propTypes = {
  onClick: PropTypes.func
};

// LinkButton wrapped with a React-Router Link
export const NavLinkButton = ({
  children,
  startIcon: StartIcon,
  endIcon: EndIcon,
  width,
  height,
  underline,
  to,
  color,
  className,
  title
}) => (
  <Link to={to}>
    <LinkButton
      startIcon={StartIcon && <StartIcon />}
      endIcon={EndIcon && <EndIcon />}
      width={width}
      height={height}
      underline={underline}
      color={color}
      className={className}
      title={title}
    >
      {children}
    </LinkButton>
  </Link>
);

NavLinkButton.propTypes = {
  children: childrenPropType,
  startIcon: PropTypes.func,
  endIcon: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  underline: PropTypes.bool,
  to: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string
};

// Edit button with React-Router Link
export const NavEditIcon = ({ to, color, className }) => (
  <NavLinkButton to={to} startIcon={Edit} color={color} className={className} />
);

NavEditIcon.propTypes = {
  to: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string
};

export const NavPreviewIcon = ({ to, color, className, title }) => (
  <NavLinkButton to={to} startIcon={FileParagraph} color={color} className={className} title={title} />
);

NavPreviewIcon.propTypes = {
  to: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string
};
