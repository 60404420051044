import useSWR from 'swr';

export interface UserInfo {
  name: string,
  email: string,
  roles: string[]
}

export const useUserInfo = () => {
  const requestUrl = '/userinfo';
  return useSWR<UserInfo>(requestUrl);
};
