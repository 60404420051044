import React, { useState } from 'react';
import styled from 'styled-components';
import 'styled-components/macro';
import { BoxButton } from '@/components/shared/BoxButton/BoxButton';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { SmallLoader } from '@/components/shared/Loader';
import { addExternalUserAsync } from '@/services/usersService';
import { ExternalCompanyContact } from '@/types';

const paragraph1Text = 'The user is already registered to';
const paragraph2Text = 'The user will be registered to multiple companies.';

const Paragraph = styled.p`
  font-size: 18px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

interface Props {
  close: () => void;
  onAdd?: () => void;
  user: ExternalCompanyContact;
  companyId: number;
  companyText: string;
}

export const PromptAppendUser = ({ close, onAdd, user, companyId, companyText }: Props) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = useState(false);
  if (error) {
    throw new Error(error);
  }
  const handleAdd = async () => {
    try {
      setSubmitting(true);
      await addExternalUserAsync(user, companyId);
      if (onAdd) {
        onAdd();
      }
    } catch (err) {
      setError(err);
    }
  };
  return (
    <div>
      <Paragraph css="margin-top: 0;">
        {paragraph1Text}
        {' '}
        {companyText}
      </Paragraph>
      <Paragraph css="margin-bottom: 32px;">{paragraph2Text}</Paragraph>
      <ButtonGroup alignRight>
        <BoxButton inverted onClick={close}>Cancel</BoxButton>
        <BoxButton onClick={handleAdd}>
          { submitting ? <SmallLoader /> : 'Add' }
        </BoxButton>
      </ButtonGroup>
    </div>
  );
};
