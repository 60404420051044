import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  ButtonGroup,
  FormField, Loader, TextField
} from '@instech/components';
import { ExternalUser, UpdateUser } from '@/types';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { SaveButton } from '@/components/shared/FormButton/FormButton';
import { stringRequiredMaxLength, stringMaxLength } from '@/utils/validationSchemas';
import { trimString } from '@/utils/text';
import { updateExternalUserAsync } from '@/services/usersService';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const personalInformationSchema = Yup.object().shape({
  name: stringRequiredMaxLength(),
  email: stringRequiredMaxLength(),
  title: stringMaxLength(),
  phoneNumber: stringMaxLength()
});

const getInitialValues = (user: ExternalUser | UpdateUser) => ({
  name: user.name,
  email: user.email,
  title: user.title ?? undefined,
  phoneNumber: user.phoneNumber ?? undefined
});

interface Props {
  user: ExternalUser;
}
export const EditUserForm = ({ user }: Props) => {
  const [error, setError] = useState();

  if (user === undefined) {
    return <Loader />;
  }

  if (error) {
    throw new Error(error);
  }

  return (
    <Formik
      initialValues={getInitialValues(user)}
      validationSchema={personalInformationSchema}
      onSubmit={async (values, { resetForm }) => {
        const { name, email, title, phoneNumber } = values;
        const request: UpdateUser = {
          name: trimString(name),
          email: trimString(email),
          title: title ? trimString(title) : null,
          phoneNumber: phoneNumber ? trimString(phoneNumber) : null,
        };

        const { error: errorMessage } = await updateExternalUserAsync(user.id, request);
        if (errorMessage) {
          setError(errorMessage);
        }
        resetForm({ values: getInitialValues(request) });
      }}
      enableReinitialize
    >
      {({ dirty, handleSubmit, isSubmitting }) => (
        <>
          <StyledForm>
            <FormField>
              <TextField
                name="name"
                placeholder="Full name"
                label="Full name"
                required
              />
            </FormField>
            <FormField>
              <TextField
                name="email"
                placeholder="Email"
                label="Email"
                required
              />
            </FormField>
            <FormField>
              <TextField
                name="title"
                placeholder="Title"
                label="Title"
              />
            </FormField>
            <FormField>
              <TextField
                name="phoneNumber"
                placeholder="Phone number"
                label="Phone number"
              />
            </FormField>
          </StyledForm>
          <ButtonGroup alignRight>
            <SaveButton
              label="UPDATE"
              title="Update"
              noIcon
              onClick={handleSubmit}
              disabled={!dirty}
              isSubmitting={isSubmitting}
            />
          </ButtonGroup>
        </>
      )}
    </Formik>
  );
};
