import { useModalContext } from '@/components/modal/ModalContext';
import { BoxButtonSlim } from '@/components/shared/BoxButton';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { withdrawAnnouncement } from '@/services/announcementsServices';
import React from 'react';
import styled, { css } from 'styled-components';

const Text = styled.p<{ bold?: boolean }>`
  font-size: 16px;
  ${props => props.bold && css`
    font-weight: bold;  
  `};
  text-align: center;
  margin-top: 0px;
  color: ${props => props.theme.marineBlue};
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 80px;
  justify-content: center;
`;

interface Props {
    announcementId: string;
    onOperationPerformed: () => void;
    recipients: number;
}
const ConfirmWithdrawModal: React.FC<Props> = ({ announcementId, onOperationPerformed, recipients }) => {
  const { close: closeModal } = useModalContext();

  const handleActionPerformed = async () => {
    await withdrawAnnouncement(announcementId);
    await onOperationPerformed();
    closeModal();
  };
  const withdrawn = `The announcement will be withdrawn from ${recipients} recipients.`;
  return (
    <>
      <Text bold>Do you wish to withdraw the annoouncement?</Text>
      <Text>{withdrawn}</Text>
      <Text>The announcement will be reverted to a draft.</Text>
      <StyledButtonGroup>
        <BoxButtonSlim width="164px" height="40px" inverted onClick={closeModal}>Cancel</BoxButtonSlim>
        <BoxButtonSlim width="164px" height="40px" onClick={handleActionPerformed}>Withdraw Announcement</BoxButtonSlim>
      </StyledButtonGroup>
    </>
  );
};

export const openConfirmWithdrawModal = (announcementId: string, recipients: number, onOperationPerformed: () => void) => ({
  component: ConfirmWithdrawModal,
  options: {
    title: 'Withdraw Announcement',
    size: 'small'
  },
  args: { announcementId, onOperationPerformed, recipients }
});
