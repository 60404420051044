import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { FormikWithPrompt } from '@instech/components';
import * as Yup from 'yup';
import { FormRow, FormField } from '@/components/shared/Form/FormLayout';
import {
  TextField, ImageUploader, TextArea
} from '@/components/shared/Form/FormFields';
import { maxFileSizeString } from '@/utils/file';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { InformationBox, InformationRow } from '@/components/shared/InformationBox';
import { uploadImageAsync } from '@/services/imageServices';
import { imageRequired, stringRequiredMaxLength } from '@/utils/validationSchemas';

interface FormModel {
  image: any;
  imagesToolImage: any;
  title: string;
  description: string;
}
const imageSchema: FormModel = {
  image: null,
  imagesToolImage: null,
  title: '',
  description: '',
};

const validationSchema = Yup.object().shape({
  imagesToolImage: imageRequired(),
  title: stringRequiredMaxLength(),
  description: Yup.string()
    .max(256, 'Cannot be longer than 256 characters')
});

interface Props {
  announcementId: string;
}
export const UploadImageForm: FunctionComponent<Props> = ({ announcementId }) => (
  <FormikWithPrompt
    initialValues={{ ...imageSchema }}
    validationSchema={validationSchema}
    onSubmit={async (values, { resetForm }) => {
      const submitValues = {
        ...values,
        image: values.imagesToolImage,
      };
      const result = await uploadImageAsync(announcementId, submitValues);
      if (result) {
        resetForm();
      }
    }}
  >
    {({ values, dirty, isSubmitting, handleSubmit, resetForm }) => (
      <Form>
        <InformationRow>
          <InformationBox text="Supported file formats: .jpg, .jpeg, .gif, .png" />
          <InformationBox text={`Max file size: ${maxFileSizeString}`} />
        </InformationRow>
        <FormRow marginBottom={values.imagesToolImage && '30px'}>
          <ImageUploader name="imagesToolImage" />
        </FormRow>
        {values.imagesToolImage && (
          <>
            <FormRow gutter="40px">
              <FormField>
                <TextField name="title" label="Image title" placeholder="Enter a title" useSpellcheck={false} />
              </FormField>
            </FormRow>
            <FormRow marginBottom="10px">
              <FormField>
                <TextArea name="description" label="Description" placeholder="Enter a description" />
              </FormField>
            </FormRow>
            <SaveCloseButtons
              closeLabel="Cancel"
              onSave={handleSubmit}
              onClose={resetForm}
              isSubmitting={isSubmitting}
              disableSave={!dirty}
              alignRight
              reverse
            />
          </>
        )}
      </Form>
    )}
  </FormikWithPrompt>
);
UploadImageForm.propTypes = {
  announcementId: PropTypes.string.isRequired
};
