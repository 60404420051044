import React from 'react';
import styled from 'styled-components';
import { Signout } from '@instech/icons';

const Dropdown = styled.div<{ isOpen: boolean }>`
  display: ${props => props.isOpen ? 'block' : 'none'};
  position: absolute;
  min-width: 280px;
  width: 100%;
  top: 50px;
  right: 0px;
  z-index: 10;
  box-shadow: 0 4px 8px -4px rgba(0,0,0,0.15);
  @media screen and (max-width: 768px) {
    width: 100%;
    border-top: 1px solid ${props => props.theme.white};
  }
`;

const DropdownInfo = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.marineBlue};
`;

const DropdownName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const DropdownText = styled.div`
  font-size: 14px;
  margin-top: 10px;
`;

const SignoutButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 18px;
  border: none;
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.marineBlue};
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.flatWhite};
  }
`;

const ButtonText = styled.div`
  margin-left: 12px;
`;

interface Props {
  name: string;
  roles: string[];
  email: string;
  onClick: () => any;
  isOpen: boolean;
}
export const UserInfoDropdown = ({ name, roles, email, onClick, isOpen }: Props) => (
  <Dropdown isOpen={isOpen}>
    <DropdownInfo>
      <DropdownName>{name}</DropdownName>
      <>
        {roles.map(role => <DropdownText key={role}>{role}</DropdownText>)}
      </>
      <DropdownText>{email}</DropdownText>
    </DropdownInfo>
    <SignoutButton onClick={onClick}>
      <Signout />
      <ButtonText>Sign Out</ButtonText>
    </SignoutButton>
  </Dropdown>
);
