export type Guid = string;

export interface Application {
  id: number;
  name: string;
}

export interface ApplicationRole {
  name: string;
  displayName: string;
}

export interface ApplicationRoles {
  id: number;
  name: string;
  applicationRoles: UserInRole[];
  canEdit: boolean;
}

export interface BusinessAccount {
  id: number;
  name: string;
}

export interface Company {
  id: number;
  mdmExternalId: Guid;
  name: string;
  type: string;
  companyNumber: number;
  city: string;
  country: string;
  email: string;
  businessAccounts: BusinessAccount[];
}

// eslint-disable-next-line no-shadow
export enum EnabledValues {
  Active = 'Active',
  Passive = 'Passive',
}

export interface ExternalUser {
  [index: string]: number | string | boolean | Guid | null | Company[] ;
  id: number;
  enabled: boolean;
  name: string;
  title: string | null;
  email: string;
  phoneNumber: string | null;
  company: string | null;
  companyId: Guid | null;
  companyNumber: number;
  companyNumericId: number;
  additionalCompanies: Company[];
}

export interface ExternalCompany {
  id: string;
  name: string;
  companyNumber: number;
  type: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  email: string;
  website: string;
  registeredAt?: string;
  lastChangedAt?: string;
  country?: string;
  city?: string
}

export interface ExternalCompanyType {
  typeCode: string;
  description: string;
}

export interface ExternalCompanyContact {
  id: Guid;
  contactNumber: number;
  companyNumber: number;
  name: string;
  title: string | null;
  phoneMobile: string;
  phoneDirect?: string;
  email: string;
  active: boolean;
}

export interface LabelValuePair {
  label: string;
  value: string;
}

export interface Portal {
  id: number,
  name: string,
  description: string,
  canEdit: boolean,
  selected: boolean
}

export type AnnouncementStatus = 'draft' | 'scheduled' | 'published';

export interface User {
  id: number;
  enabled: boolean;
  displayName: string;
  title: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  companyId: string;
}

export interface UserInRole {
  id: number;
  name: string;
  selected: boolean;
}

export interface Article {
  text: string;
  images: string[];
}

export interface Provider {
  count: number;
  providerId: number;
  selected: boolean;
  displayName: string;
  editableByCurrentUser?: boolean;
}

export interface UpdateUserInRole {
  providerId: number;
  subjectId: string;
  selected: boolean;
  displayName: string;
  company: string;
}

export interface UpdateApplicationRole {
  count: number;
  providerId: number;
  selected: boolean;
  displayName: string;
  name: string;
  users: UpdateUserInRole[];
  editableByCurrentUser?: boolean;
}

export interface UpdateUser {
  name: string;
  title: string | null;
  email: string;
  phoneNumber: string | null;
}

export interface UpdateApplication {
  count: number;
  providerId: number;
  selected: boolean;
  displayName: string;
  name: string;
  roles: UpdateApplicationRole[];
  editableByCurrentUser?: boolean;
}

export interface RecipientStructure {
  count: number;
  selectedCount: number;
  providers: Provider[];
  applications: UpdateApplication[];
}

export interface UserInfo {
  id: string;
  email: string;
  name?: string;
}

export interface Operations {
  edit: boolean;
  delete: boolean;
  publish: boolean;
  duplicate: boolean;
  withdraw: boolean;
}

export interface Announcement {
  id: string;
  published: boolean;
  isDuplicate: boolean;
  notVisibleBefore?: string;
  lastUpdated: string;
  publishedDate: string;
  publishedBy: UserInfo;
  categoryOption: Option;
  cardImageId?: string;
  keywordOption: Option;
  title: string;
  shortText: string;
  articles: Article[];
  recipientStructure: RecipientStructure;
  readCount: number;
  percentageRead: number;
  operations: Operations;
}

export interface Dictionary<T = any> {
  [key: string]: T;
}

export interface Category {
  name: string;
  displayText: string;
  options: Option[];
  isGeneral: boolean;
}

export interface Keyword {
  name: string;
  displayText: string;
  options: Option[];
  isGeneral: boolean;
}

export interface Option {
  label: string;
  value: string;
}

export interface ContextMenuCallbacks {
  onDraftDelete?: () => void;
  onWithdrawn?: () => void;
  onDublicate?: () => void;
}
