import React, { FunctionComponent } from 'react';
import { BoxButton, ButtonProps } from './BoxButton';

export const BoxButtonSlim: FunctionComponent<ButtonProps> = Props => (
  <BoxButton
    {...Props}
    padding="8px 12px"
  >
    {Props.children}
  </BoxButton>
);
