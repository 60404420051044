import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

interface ContainerProps {
  alignRight?: boolean;
  marginTop?: string;
  fullWidth?: boolean;
}
const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: ${props => props.alignRight ? 'flex-end' : props.fullWidth && 'space-between'};
  margin-top: ${props => props.marginTop || ''};
`;

interface ButtonsProps {
  spaceBetween?: boolean;
  reverse?: boolean;
}
const Buttons = styled.div<ButtonsProps>`
  display: flex;
  flex-flow: ${props => props.reverse ? 'row-reverse' : 'row'};
  ${props => props.spaceBetween && css`
    width: 100%;
    justify-content: space-between;
  `}

  & > button + button {
    margin-left: ${props => !props.reverse && '16px'};
    margin-right: ${props => props.reverse && '16px'};
  }
`;

type Props = ContainerProps & ButtonsProps & { className?: string };
export const ButtonGroup: FunctionComponent<Props> = ({ children, alignRight, spaceBetween, reverse, marginTop, className, fullWidth }) => (
  <Container alignRight={alignRight} fullWidth={fullWidth} marginTop={marginTop} className={className}>
    <Buttons spaceBetween={spaceBetween || fullWidth} reverse={reverse}>
      {children}
    </Buttons>
  </Container>
);
