import React from 'react';
import { UpdateArticle, useLightboxContext } from '@instech/components';
import { Announcement } from '@/types';
import { useImagePreviewById } from '@/services/imageServices';
import { TitleAndEdit } from './core/Components';

interface Props {
  announcement: Announcement;
  onEdit: () => void;
}
export const ArticlePreview = ({ announcement, onEdit }: Props) => {
  const { open: openInLightbox } = useLightboxContext();
  return (
    <div>
      <TitleAndEdit title="Article Preview" onEdit={onEdit} />
      <UpdateArticle
        title={announcement.title}
        publisher={{
          title: '', // What prop from Update is supposed to go in here?
          name: announcement.publishedBy?.name || ''
        }}
        sections={announcement.articles}
        imageLoader={useImagePreviewById}
        openInLightbox={openInLightbox}
      />
    </div>
  );
};
