import React from 'react';
import {
  Route, Switch, useRouteMatch
} from 'react-router-dom';
import { PageContent } from '@/components/layout/Page';
import { SelectCompanyStep } from './stepComponents/SelectCompanyStep/SelectCompanyStep';
import { AddPortalStep } from './stepComponents/AddPortalStep/AddPortalStep';
import { AddUsersStep } from './stepComponents/AddUsersStep/AddUsersStep';
import { CompanyOverviewStep } from './stepComponents/CompanyOverviewStep/CompanyOverviewStep';
import { AddCompanyStep } from './stepComponents/AddCompanyStep/AddCompanyStep';

export const EnrollmentPage = () => {
  const { path } = useRouteMatch();

  return (
    <PageContent>
      <Switch>
        <Route path={path} exact>
          <SelectCompanyStep />
        </Route>
        <Route path={`${path}/enroll/:companyId`}>
          <AddCompanyStep />
        </Route>
        <Route path={`${path}/add-portal/:companyId`}>
          <AddPortalStep />
        </Route>
        <Route path={`${path}/add-users/:companyId`}>
          <AddUsersStep />
        </Route>
        <Route path={`${path}/company-overview/:companyId`}>
          <CompanyOverviewStep />
        </Route>
      </Switch>
    </PageContent>
  );
};
