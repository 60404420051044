import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@/components/shared/FormlessFields/Checkbox';
import { Loader } from '@/components/shared/Loader';
import { updateCompanyApplicationAsync, usePortalApplications } from '@/enrollment/services/services';
import { useModalContext } from '@/components/modal/ModalContext';
import { Portal } from '@/types';
import { openConfirmRemovalModal } from './PortalConfirmRemoval';

// @TODO: Move portal selection to its own component in the shared/component folder when SURVEY-1996 is complete.

const Item = styled.div`
  background: #E5EBF1;
  color: ${props => props.theme.marineBlue};
  padding: 20px 32px;
  margin: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 64px;
  input {
    height: 20px;
    width: 20px;
  }
`;

const Container = styled.div`
  margin-top: 32px;
  margin-bottom: 40px;
`;

const Title = styled.div`
  color: ${props => props.theme.marineBlue};
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const Area = styled.div`
  background: white;
  padding: 12px 24px;
`;

interface PortalEntryProps extends Portal {
  companyId: number
}

const PortalEntry = (props: PortalEntryProps) => {
  const { open, close } = useModalContext();

  const modal = openConfirmRemovalModal({
    portalName: props.name,
    confirmHandler: () => {
      void updateCompanyApplicationAsync(props.companyId, props.id, false);
      close();
    },
    cancelHandler: () => { close(); }
  });

  const onSubmit = async () => {
    if (props.selected) {
      open(modal);
    } else {
      void updateCompanyApplicationAsync(props.companyId, props.id, true);
    }
  };

  return (
    <Item>
      <div>
        <Title>{props.name}</Title>
        <div>{props.description}</div>
      </div>
      <Checkbox
        role="checkbox"
        selected={props.selected}
        onClick={props.canEdit ? onSubmit : undefined}
        disabled={!props.canEdit}
      />
    </Item>
  );
};

interface PortalProps {
  title: string,
  companyId: any
}
export const PortalSelection = ({ companyId, title }: PortalProps) => {
  const { data: portals, error } = usePortalApplications(companyId);

  if (error) {
    throw new Error(error);
  }

  if (!portals) {
    return <Loader />;
  }

  return (
    <Container>
      <Title>{title}</Title>
      <Area>
        {portals.map(p => <PortalEntry key={p.id} companyId={companyId} {...p} />)}
      </Area>
    </Container>
  );
};
