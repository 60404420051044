import { Loader } from '@/components/shared/Loader';
import { AddCompanyProgress } from '@/enrollment/core/AddCompanyProgress';
import { NextButton } from '@/enrollment/core/NextButton';
import { TopSection } from '@/enrollment/core/TopSection';
import { useCompany } from '@/services/companiesService';
import React from 'react';
import { useParams } from 'react-router';

// TODO: implement
export const CompanyOverviewStep = () => {
  const { companyId } = useParams() as any;

  const { data: company, error } = useCompany(companyId);

  if (error) {
    throw new Error(error);
  }

  if (!company) {
    return <Loader />;
  }

  return (
    <div>
      <pre>{JSON.stringify(company, undefined, 2)}</pre>
      <TopSection>
        <AddCompanyProgress currentStep={3} />
        <NextButton submit={() => { }} />
      </TopSection>
      <h1>TODO: Company overview</h1>
    </div>
  );
};
