import styled, { css } from 'styled-components';

export const FieldLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ErrorMessage = styled.span`
  display: block;
  height: 15px;
  margin-top: 5px;
  color: ${props => props.theme.red};
  font-size: 11px;
  text-align: right;
`;

// Updated to match current style in FrontendComponents, but should
// probably be deprecated in favour of an export from there
export const FieldLabel = styled.label`
  display: inline-block;
  height: 15px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
  color: ${props => props.theme.marineBlue};
  cursor: pointer;
`;

export const EmptyLabel = styled.span`
  height: 15px;
  margin-bottom: 12px;
`;

interface InputFieldProps {
  error?: string | boolean;
}
export const InputField = styled.div<InputFieldProps>`
  & select,
  & input,
  & textarea {
    width: 100%;
    height: 40px;
    padding: 0px 16px;
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px solid;
    background-color: ${props => props.theme.white};
    border-color: ${props => props.theme.border.gray};
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
    appearance: none;
    
    ${props => props.error && css`
      border-color: ${props.theme.red};
      color: ${props.theme.red};
      &::placeholder {
        color: ${props.theme.red};
        opacity: 0.6;
      }
    `};
    &:disabled {
      color: ${props => props.theme.black};
      background-color: ${props => props.theme.background.primary};
    }
  }

  /* Extend styles for textarea */
  & textarea {
    height: 60px;
    padding: 8px 16px;
    resize: vertical;
  }
`;

export const InputFieldArea = styled.div<InputFieldProps>`
  & textarea {
    width: 100%;
    height: 78px;
    min-height: 40px;
    padding: 8px 16px;
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px solid;
    border-color: ${props => props.theme.border.gray};
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
    appearance: none;
    resize: vertical;

    ${props => props.error && css`
      border-color: ${props.theme.red};
      color: ${props.theme.red};
      &::placeholder {
        color: ${props.theme.red};
        opacity: ${props.theme.opacity.placeholder};
      }
    `};
  }
`;
