import React, { useState } from 'react';
import styled from 'styled-components';
import { Loader } from '@/components/shared/Loader';
import {
  Table, TableCell, TableHeader, TableRow, CellButton, CellButtonChevron
} from '@/components/shared/Table';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { BoxButton } from '@/components/shared/BoxButton/BoxButton';
import { useUserCompanyRoles } from '@/services/usersService';
import { useModalContext } from '@/components/modal/ModalContext';
import {
  ApplicationRoles,
  User,
  ExternalUser
} from '@/types';
import { RoleSelectPopup } from './RoleSelectPopup';

const HeaderRow = () => (
  <>
    <TableHeader>Portal</TableHeader>
    <TableHeader>Roles</TableHeader>
    <TableHeader />
  </>
);

const ButtonCell = styled(TableCell)`
  padding: 0;
  display: block;
` as any;

interface ApplicationRowProps {
  application: ApplicationRoles;
  even: boolean;
  showRoleSelect?: boolean;
  setShowRoleSelect: () => void;
  userId: number;
}
const ApplicationRow = ({
  application,
  even,
  showRoleSelect,
  setShowRoleSelect,
  userId
}: ApplicationRowProps) => {
  const userRoles = application.applicationRoles.filter(r => r.selected);
  // const userRoles = application.applicationRoles;

  const rolesText = userRoles.length > 0 ? userRoles.map(r => r.name).join(', ') : 'No access';

  return (
    <TableRow even={even}>
      <TableCell>{application.name}</TableCell>
      <TableCell>{rolesText}</TableCell>
      <ButtonCell>
        <CellButton
          ariaLabel="Manage roles"
          icon={<CellButtonChevron bottom thin />}
          onClick={setShowRoleSelect}
        />
        {showRoleSelect && (
          <RoleSelectPopup
            onClose={setShowRoleSelect}
            roles={application.applicationRoles}
            canEdit={application.canEdit}
            onSave={setShowRoleSelect}
            applicationId={application.id}
            userId={userId}
          />
        )}
      </ButtonCell>
    </TableRow>
  );
};

interface Props {
  user: User | ExternalUser;
}

export const UserRoleSelect = ({ user }: Props) => {
  const { data: applicationRoles, error } = useUserCompanyRoles(user.id);
  const [activeRoleSelectPopup, setActiveRoleSelectPopup] = useState<number | undefined>(undefined);
  const { close } = useModalContext();

  if (error) {
    throw new Error(error);
  }
  if (!applicationRoles) {
    return <Loader />;
  }

  const toggleRoleSelect = (applicationId?: number) => setActiveRoleSelectPopup(activeRoleSelectPopup !== applicationId ? applicationId : undefined);

  return (
    <div>
      <Table gridTemplateColumns="2fr 3fr 60px">
        <HeaderRow />
        {applicationRoles.map((application, i) => (
          <ApplicationRow
            key={application.id}
            even={i % 2 === 0}
            application={application}
            showRoleSelect={activeRoleSelectPopup === application.id}
            setShowRoleSelect={() => toggleRoleSelect(application.id)}
            userId={user.id}
          />
        ))}
      </Table>
      <ButtonGroup alignRight marginTop="20px">
        <BoxButton width="100px" onClick={close}>
          DONE
        </BoxButton>
      </ButtonGroup>
    </div>
  );
};
