import React from 'react';
import styled from 'styled-components';
import { InfoCircle } from '@instech/icons';

const StyledLabel = styled.span`
  color: ${props => props.theme.marineBlue};
  text-decoration: underline dotted ${props => props.theme.marineBlue};
  cursor: pointer;
  & svg {
    margin-left: 4px;
    margin-bottom: -2px;
  }
`;

interface Props {
  text: string;
  title: string;
}
/* TODO:
  At a later point, this component should likely use a proper tooltip
  component rather than just a HTML title?
*/
export const ShortenedLabel = ({ text, title }: Props) => (
  <StyledLabel title={title}>
    {text}
    <InfoCircle height="14px" width="14px" />
  </StyledLabel>
);
