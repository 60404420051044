import React, {
  createContext, useContext, FunctionComponent, useMemo
} from 'react';

interface FormIdData {
  formId: string;
}
const FormIdContext = createContext<FormIdData>({ formId: '' });

export const FormIdProvider: FunctionComponent<FormIdData> = ({ children, formId }) => {
  const value = useMemo(() => ({ formId }), [formId]);
  return (
    <FormIdContext.Provider value={value}>
      {children}
    </FormIdContext.Provider>
  );
};

/**
 * Gets a unique fieldId that can be used to bind label/input with the htmlFor/id props respectfully
 *
 * If there are multiple instances of the same form on the same page, we cannot use 'name' directly
 * to bind label/input as it's not unique. Therefore, we prefix the name with a unique 'formId'.
 *
 * Requires that a unique formId is set with FormIdProvider higher up in the tree, e.g. as a child
 * directly under the Formik component. If the unique 'formId' is not set it will return the 'name'
 * without the prefix.
 */
export const useFieldId = (fieldName: string) => {
  const { formId } = useContext(FormIdContext);
  const idPrefix = formId ? `${formId}_` : '';

  return {
    formId,
    fieldId: `${idPrefix}${fieldName}`
  };
};
