import React, { useState } from 'react';
import styled from 'styled-components';
import {
  ButtonGroup, DangerNotification, InformationNotification, SlimButton, WarningNotification
} from '@instech/components';
import { ExternalUser } from '@/types';
import { ModalLayout } from '@/components/shared/ModalLayout';
import { useModalContext } from '@/components/modal/ModalContext';
import { resetMfaAsync } from '@/services/usersService';

const warningHeading = 'Are you sure you want to reset the users\' MFA?';
const warningDescription = 'The user will have to re-enroll their MFA app';

const Warning = styled.div`
  margin-bottom: 20px;
`;

interface ResetFormProps {
  onReset: () => void;
  onCancel: () => void;
  isLoading: boolean;
  isError: boolean;
}
const ResetForm = ({ onReset, onCancel, isLoading, isError }: ResetFormProps) => (
  <>
    <Warning>
      <WarningNotification size="medium" headingText={warningHeading} descriptionText={warningDescription} />
    </Warning>
    <ButtonGroup alignRight>
      <SlimButton onClick={onReset} loading={isLoading} disabled={isLoading || isError}>
        Reset MFA
      </SlimButton>
      <SlimButton onClick={onCancel} variant="secondary">
        Cancel
      </SlimButton>
    </ButtonGroup>
  </>
);

const CompletedView = ({ name, onClose }: { name: string; onClose: () => void; }) => (
  <>
    <Warning>
      <InformationNotification
        size="medium"
        headingText={`The MFA for ${name} has been successfully reset.`}
      />
    </Warning>
    <ButtonGroup alignRight>
      <SlimButton onClick={onClose} variant="secondary">
        Close
      </SlimButton>
    </ButtonGroup>
  </>
);

const ErrorView = ({ name, onClose }: { name: string; onClose: () => void; }) => (
  <>
    <Warning>
      <DangerNotification size="medium" headingText={`An error occurred while resetting MFA for ${name}`} />
    </Warning>
    <ButtonGroup alignRight>
      <SlimButton onClick={onClose} variant="secondary">
        Close
      </SlimButton>
    </ButtonGroup>
  </>
);

const ResetMfa = ({ user }: Props) => {
  const { close } = useModalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isError, setIsError] = useState(false);

  const onError = () => {
    setIsError(true);
    setIsLoading(false);
    setIsCompleted(true);
  };
  const onCompleted = () => {
    setIsLoading(false);
    setIsCompleted(true);
  };

  const onReset = async () => {
    setIsLoading(true);
    await resetMfaAsync(user.id)
      .then(onCompleted)
      .catch(onError);
  };

  if (isError) {
    return <ErrorView name={user.name} onClose={close} />;
  }

  if (isCompleted) {
    return <CompletedView name={user.name} onClose={close} />;
  }

  return <ResetForm onReset={onReset} onCancel={close} isLoading={isLoading} isError={isError} />;
};

interface Props {
  user: ExternalUser;
}
const ResetMfaModal = ({ user }: Props) => (
  <ModalLayout userName={user.name}>
    <ResetMfa user={user} />
  </ModalLayout>
);

export const openResetMfaModal = ({ user }: Props) => ({
  component: ResetMfaModal,
  options: {
    footer: true,
    size: 'medium',
    padding: 0
  },
  args: { user }
});
