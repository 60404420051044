import React, { FunctionComponent } from 'react';
import { Loader } from '@/components/shared/Loader';
import {
  Table, TableCell, TableHeader, TableRow
} from '@/components/shared/Table';
import { useCompanyContacts } from '@/enrollment/services/services';
import { ExternalCompanyContact } from '@/types';
import { Heading2 } from '@/components/shared/Headings/Heading2';

const HeaderRow = () => (
  <>
    <TableHeader>Name</TableHeader>
    <TableHeader>Title</TableHeader>
    <TableHeader>Email</TableHeader>
    <TableHeader>Phone</TableHeader>
    <TableHeader>Status</TableHeader>
  </>
);

interface RowProps {
  contact: ExternalCompanyContact;
  even: boolean;
}
export const Row: FunctionComponent<RowProps> = ({ contact, even }) => (
  <TableRow even={even}>
    <TableCell>{contact.name}</TableCell>
    <TableCell>{contact.title}</TableCell>
    <TableCell>{contact.email}</TableCell>
    <TableCell>{contact.phoneMobile || contact.phoneDirect}</TableCell>
    <TableCell>{contact.active ? 'Active' : 'Passive'}</TableCell>
  </TableRow>
);

interface ContactsListProps {
  companyNumber: number
}
export const ContactsList = ({ companyNumber }: ContactsListProps) => {
  const { data: contacts } = useCompanyContacts(companyNumber);

  if (!contacts) {
    return <Loader />;
  }

  return (
    <div>
      <Heading2>Contacts</Heading2>
      <Table gridTemplateColumns="1fr 1fr 1fr 1fr 1fr">
        <HeaderRow />
        {contacts.map((contact, i) => (
          <Row
            key={contact.id}
            contact={contact}
            even={i % 2 === 0}
          />
        ))}
      </Table>
    </div>
  );
};
