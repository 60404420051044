import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useAccount } from 'its-js-authentication';
import { AccountCircle, Close } from '@instech/icons';
import { useOutsideAlerter } from '@/utils/mouseEventHelpers';
import { UserInfoDropdown } from './UserInfoDropdown';
import { useUserInfo } from './UserInfoService';

const Anchor = styled.div`
  position: relative;
  height: 50px;
  align-self: flex-end;
  @media screen and (max-width: 768px) {
    position: initial;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  height: 50px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    margin-right: -16px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
  text-align: right;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Name = styled.div`
  font-size: 14px;
`;

const Role = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 2px;
`;

// A little display juggling to sort out when to show what icon, as
// it differs between desktop and mobile display

const StyledAccountCircle = styled(AccountCircle) <{ hide: boolean }>`
  ${props => props.hide && css`
    @media screen and (max-width: 768px) {
      display: none;
    }  
  `}
`;

const StyledClose = styled(Close) <{ show: boolean }>`
  display: none;
  ${props => !props.show && css`
    @media screen and (max-width: 768px) {
      color: ${props.theme.white};
      display: block;
    }  
  `}
`;

export const UserInfo = () => {
  const { data } = useUserInfo();
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);
  const { logout } = useAccount();

  const userName = data?.name || '';
  const userRole = data?.roles[0] || '';
  const userEmail = data?.email || 'Unknown email';

  const onOutsideClick = () => setIsOpen(false);
  useOutsideAlerter(anchorRef, true, onOutsideClick);

  return (
    <Anchor ref={anchorRef}>
      <Container onClick={() => setIsOpen(!isOpen)}>
        <TextContainer>
          <Name>{userName}</Name>
          <Role>{userRole.toUpperCase()}</Role>
        </TextContainer>
        <StyledAccountCircle hide={isOpen} width={24} />
        <StyledClose show={!isOpen} width={24} />
      </Container>
      {data && (
        <UserInfoDropdown
          name={userName}
          roles={data.roles}
          email={userEmail}
          onClick={logout}
          isOpen={isOpen}
      />
      )}
    </Anchor>
  );
};
