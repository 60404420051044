import { PageContent } from '@/components/layout/Page';
import { useModalContext } from '@/components/modal/ModalContext';
import { Loader } from '@/components/shared/Loader';
import { createEmptyDraft } from '@/services/announcementsServices';
import React, { useEffect, useState } from 'react';
import {
  Redirect, Route, Switch, useRouteMatch
} from 'react-router';
import { CancelUpdateConfirmationModalData, openConfirmationModal } from './core/ConfirmationModal';
import { ProgressProvider } from './core/ProgressContext';
import { AnnouncementStateProvider } from './core/AnnouncementStateContext';
import { PreviewPublish } from './PreviewPublish';
import { SelectRecipients } from './SelectRecipients';
import { AnnouncementContents } from './AnnouncementContents';
import { routes } from './utils';

export type Step = { step: number, name: string, route: string };
const steps: Step[] = [
  {
    step: 1,
    name: 'Product & Recipients',
    route: 'recipients'
  },
  {
    step: 2,
    name: 'Text, Images & Video',
    route: 'content'
  },
  {
    step: 3,
    name: 'Preview & Publish',
    route: 'preview'
  }
];

const CreateDraftAndRedirect = () => {
  const [announcementId, setAnnouncementId] = useState<string | null>(null);
  const { path } = useRouteMatch();

  useEffect(() => {
    void createEmptyDraft()
      .then(result => setAnnouncementId(result.id))
      .catch(error => { throw new Error(error); });
  }, []);

  if (!announcementId) return <Loader />;

  return <Redirect to={`${path}/${announcementId}`} />;
};

export const NewAnnouncementPage = () => {
  const { path } = useRouteMatch();
  const { open } = useModalContext();

  const handleCancel = (returnToCallBack?: () => void) => {
    const args = { ...CancelUpdateConfirmationModalData, returnToCallBack };
    open(openConfirmationModal({ title: 'Discard changes' }, args));
  };

  return (
    <PageContent>
      <Switch>
        <Route exact path={routes.newAnnouncement}>
          <CreateDraftAndRedirect />
        </Route>
        <ProgressProvider initialPath={path}>
          <AnnouncementStateProvider>
            <Route exact path={routes.recipients}>
              <SelectRecipients
                steps={steps}
                stepNum={0}
                handleCancel={handleCancel}
              />
            </Route>
            <Route path={routes.content}>
              <AnnouncementContents
                steps={steps}
                stepNum={1}
                handleCancel={handleCancel}
              />
            </Route>
            <Route path={routes.preview}>
              <PreviewPublish
                steps={steps}
                stepNum={2}
                handleCancel={handleCancel}
              />
            </Route>
          </AnnouncementStateProvider>
        </ProgressProvider>
      </Switch>
    </PageContent>
  );
};
