import React from 'react';
import { InUseDialog } from './InUseDialog';

interface ModalObject {
  component: React.ComponentType;
  options: {
    [key: string]: any;
  },
  args: {
    [key: string]: any;
  }
}

interface InUseDialogProps {
  open: (modalObj: ModalObject) => void;
}
export const openInUseDeleteDialog = ({ open }: InUseDialogProps) => {
  const modalObj: ModalObject = {
    component: InUseDialog as React.ComponentType,
    options: {
      size: 'small'
    },
    args: {
    }
  };
  open(modalObj);
};
