import {
  ApplicationRoles, ExternalCompanyContact, ExternalUser, UpdateUser, User
} from '@/types';
import { encodeURIPart } from '@/utils/encoding';
import axios from 'axios';
import useSWR, { mutate } from 'swr';
import client, {
  getJsonAsync, postAsync, postJsonAsync
} from './client';

const usersCacheKey = '/user';

export const useUsers = () =>
  useSWR<ExternalUser[]>(usersCacheKey);

export const useCompanyUsers = (companyId: string) => {
  const requestUrl = `/company/${companyId}/users`;
  return useSWR<User[]>(requestUrl);
};

export const userByEmailCacheKey = (email: string) => `/user/email?email=${encodeURIPart(email)}`;

// Just as a normal GET, but with null on 404 responses
const getUser = async (url: string) => {
  try {
    return await getJsonAsync<ExternalUser>(url);
  } catch (error) {
    if (axios.isAxiosError(error) && error?.response?.status === 404) return null;
    throw error;
  }
};

export const useUser = (userEmail: string) => {
  const requestUrl = userByEmailCacheKey(userEmail);
  return useSWR<ExternalUser | null>(requestUrl, url => getUser(url));
};

export const addExternalUserAsync = async (user: ExternalCompanyContact, companyId: number) => {
  const result = await client.post<User>('/user/external', {
    name: user.name,
    email: user.email,
    title: user.title,
    phoneNumber: user.phoneMobile || user.phoneDirect,
    companyId
  });
  await mutate(userByEmailCacheKey(user.email), result.data);
  return result;
};

const userCompanyRolesUrl = (userId: number) => `/user/${userId}/company-roles`;

export const useUserCompanyRoles = (userId: number) => useSWR<ApplicationRoles[]>(userCompanyRolesUrl(userId));

export const setUserRolesAsync = async (userId: number, applicationId: number, roles: number[]) => {
  const requestUrl = `/user/${userId}/roles/${applicationId}`;
  const response = await client.post(requestUrl, roles);
  await mutate(userCompanyRolesUrl(userId));
  return response;
};

export const updateExternalUserAsync = async (userId: number, request: UpdateUser) => {
  const requestUrl = `/user/external/${userId}`;
  let error;
  const data = await postJsonAsync<User>(requestUrl, request)
    .catch(e => { error = e; });
  await mutate(usersCacheKey);
  return { data, error };
};

export const resetMfaAsync = async (userId: number) => {
  const requestUrl = `/user/external/${userId}/mfa`;
  return postAsync(requestUrl);
};
