import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const ContainerLine = styled.div`
  background-color: ${props => props.theme.white};
  height: 6px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
`;

const ProgressLine = styled.div`
  width: 100%;
  height: 100%;
`;

const CompletedLine = styled.div<{ step: number, numSteps: number }>`
  background-color: green;
  width: ${props => ((props.step + 1) / props.numSteps) * 100}%;
  height: 6px;
  border-radius: 2px 3px 3px 2px;
`;

const Steps = styled.ol` 
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
`;

const Step = styled.li<{ currentStep: boolean, numSteps: number }>`
  display: block;
  flex-basis: 1;
  flex-grow: 0;
  width: ${props => 100 / props.numSteps}%;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.currentStep ? props.theme.marineBlue : props.theme.marineBlue50};
  padding: 0px 12px;
  box-sizing: border-box;
  &:first-of-type {
    padding-left: 32px;
    text-align: left;
  }
  &:last-of-type {
    padding-right: 32px;
    text-align: right;
  }
`;

interface StepType {
  name: string;
  step: number;
}
interface Props {
  steps: StepType[];
  step: number;
  className?: string;
}
export const ProgressBar: FunctionComponent<Props> = ({ steps, step, className }) => {
  const numSteps = Object.keys(steps).length;
  return (
    <Container>
      <ContainerLine className={className}>
        <ProgressLine>
          <CompletedLine step={step} numSteps={numSteps} />
        </ProgressLine>
      </ContainerLine>
      <Steps>
        {steps && steps.map((each, i) => (
          <Step key={each.step} currentStep={i === step} numSteps={numSteps}>
            {each.step}
            .&nbsp;
            {each.name}
          </Step>
        ))}
      </Steps>
    </Container>
  );
};
