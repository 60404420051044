import React, { FunctionComponent } from 'react';
import {
  StyledButton, Container, TextContainer, IconContainer
} from './core/Components';

export interface ButtonProps {
  inverted?: boolean;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  width?: string;
  height?: string;
  fontSize?: string;
  boldFont?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  title?: string;
  padding?: string;
  ariaLabel?: string;
  className?: string;
}
export const BoxButton: FunctionComponent<ButtonProps> = (
  {
    children,
    inverted,
    startIcon: StartIcon,
    endIcon: EndIcon,
    width,
    height,
    fontSize,
    boldFont = true,
    onClick,
    disabled,
    type = 'button',
    title = '',
    padding,
    ariaLabel,
    className
  }
) => (
  <StyledButton
    inverted={inverted}
    width={width}
    height={height}
    onClick={onClick}
    type={type}
    disabled={disabled}
    title={title}
    aria-label={ariaLabel}
    className={className}
  >
    <Container padding={padding}>
      {StartIcon && <IconContainer start="true">{StartIcon}</IconContainer>}
      <TextContainer fontSize={fontSize} boldFont={boldFont}>{children}</TextContainer>
      {EndIcon && <IconContainer end="true">{EndIcon}</IconContainer>}
    </Container>
  </StyledButton>
);
