import { baseTheme } from '@instech/components';

export const theme = {
  ...baseTheme,
  header: {
    height: '100px',
    scrolledHeight: '50px'
  }
  /* Additional style declarations go here */
};

export type Theme = typeof theme;
