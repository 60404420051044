import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '@instech/components';
import { Add, Trash } from '@instech/icons';

interface Props {
  onClick: () => any;
  disabled?: boolean;
}

const AddButton = styled(LinkButton)`
  height: 32px;
`;
export const AddSectionButton = ({ onClick, disabled }: Props) => (
  <AddButton startIcon={<Add />} onClick={onClick} disabled={disabled}>
    Add Section
  </AddButton>
);

// A little CSS trickery to make the Delete button appear as though
// it's on the top-right of a RichText field in the space of a label
// even though it is outside of its scope in the DOM.
const DeleteButtonWrapper = styled.div`
  position: relative;
  height: 0;
  & > button {
    position: absolute;
    right: 0;
    top: -5px;
  }
`;
export const DeleteSectionButton = ({ onClick, disabled }: Props) => (
  <DeleteButtonWrapper>
    <LinkButton endIcon={<Trash width={16} />} onClick={onClick} disabled={disabled}>
      Delete Section
    </LinkButton>
  </DeleteButtonWrapper>
);
