import React, {
  FunctionComponent, useState, useEffect
} from 'react';
import { useUsers } from '@/services/usersService';
import {
  EnabledValues,
  ExternalUser
} from '@/types';
import styled from 'styled-components';
import { openManageUserAccessModal } from '@/enrollment/stepComponents/AddUsersStep/ManageAccessModal/ManageUserAccessModal';
import { companyTitle } from '@/utils/textutils';
import {
  ComplexTable,
  ComplexTableHeaders,
  Loader,
  SortedTableHeader,
  SortOrder
} from '@instech/components';
import { PageContent } from '../../layout/Page';
import {
  TableCell,
  TableRow,
  CellButton,
  CellButtonChevron
} from '../../shared/Table';
import { useModalContext } from '../../modal/ModalContext';
import { openEditUserModal } from './EditUserModal';
import { openResetMfaModal } from './ResetMfaModal';

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
  margin: 30px 0px;
`;

const ButtonCell = styled(TableCell)`
  padding-right: 12px;
  justify-content: flex-end;
`;

const headers=[
  { parameterName: 'name', propertyName: 'name', title: 'Name' },
  { parameterName: 'title', propertyName: 'title', title: 'Title' },
  { parameterName: 'email', propertyName: 'email', title: 'Email' },
  { parameterName: 'phoneNumber', propertyName: 'phoneNumber', title: 'Phone' },
  { parameterName: 'company', propertyName: 'company', title: 'Company' },
  { parameterName: 'enabled', propertyName: 'enabled', title: 'Active / Passive' },
  { parameterName: 'manage access', propertyName: 'manage access', title: 'Manage access' },
  { parameterName: 'edit user', propertyName: 'edit user', title: 'Edit user' },
  { parameterName: 'reset MFA', propertyName: 'reset MFA', title: 'Reset MFA' }
];

interface RowProps { user: ExternalUser; even: boolean; }

const sortFunction= (a: string, b: string, sortOrder: string) => {
  const curr = a.replace(/ /g, '').toLowerCase();
  const next = b.replace(/ /g, '').toLowerCase();
  if (sortOrder === SortOrder.Ascending) return curr > next ? 1 : -1;
  return curr < next ? 1 : -1;
};

export const Row: FunctionComponent<RowProps> = ({ user, even }) => {
  const manageAccessDisabled = !user.email || !user.enabled;
  const editUserDisabled = !user.enabled;
  const { open } = useModalContext();

  const showAccessModal = openManageUserAccessModal({ user });
  const editUserModal = openEditUserModal({ user });
  const resetMfaModal = openResetMfaModal({ user });

  return (
    <TableRow even={even}>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.title}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.phoneNumber}</TableCell>
      <TableCell>{companyTitle(user)}</TableCell>
      <TableCell>{user.enabled ? 'Active' : 'Passive'}</TableCell>
      <ButtonCell>
        <CellButton
          ariaLabel={`Manage access for ${user.name}`}
          disabled={manageAccessDisabled}
          icon={<CellButtonChevron right thin disabled={manageAccessDisabled} />}
          onClick={!manageAccessDisabled ? () => open(showAccessModal) : undefined}
        />
      </ButtonCell>
      <ButtonCell>
        <CellButton
          ariaLabel={`Edit user info for ${user.name}`}
          disabled={editUserDisabled}
          icon={<CellButtonChevron right thin disabled={editUserDisabled} />}
          onClick={!editUserDisabled ? () => open(editUserModal) : undefined}
        />
      </ButtonCell>
      <ButtonCell>
        <CellButton
          ariaLabel={`Reset MFA for ${user.name}`}
          disabled={manageAccessDisabled}
          icon={<CellButtonChevron right thin disabled={manageAccessDisabled} />}
          onClick={!manageAccessDisabled ? () => open(resetMfaModal) : undefined}
        />
      </ButtonCell>
    </TableRow>
  );
};

export const UsersPage = () => {
  const { data: users } = useUsers();
  const [tableData, setTableData] = useState<ExternalUser [] | undefined>();
  const [sortedHeader, setSortedHeader] = useState<SortedTableHeader>();
  const columns = users && Object.fromEntries(Object.entries(users![0]).map(([k, _]) => [k, new Set<string>()]));
  const facets = users && users.reduce((acc, next) => {
    Object.entries(next).forEach(([key, value]) => {
      acc![key].add(value as string);
    });
    return acc;
  }, columns);

  useEffect(() => {
    setTableData(users);
  }, [users]);

  const filterByFacets = (param: string, selected: string[]) => {
    if (selected.length > 0 && users) {
      const filteredTable = users.filter(row => selected.includes(row[param] as string));
      setTableData(filteredTable);
      setSortedHeader({ propertyName: param, sortOrder: SortOrder.NotSorted });
    } else {
      setTableData(users!);
      setSortedHeader({ propertyName: '', sortOrder: SortOrder.NotSorted });
    }
  };

  const filterData = (header: SortedTableHeader) => {
    const param = header.propertyName;
    if (tableData) {
      const result = [...tableData].sort((a: any, b: any) => {
        if (typeof a[param] === 'boolean' && typeof b[param] === 'boolean') {
          const curr = (a[param] === true) ? EnabledValues.Active : EnabledValues.Passive;
          const next = (b[param] === true) ? EnabledValues.Active : EnabledValues.Passive;
          return sortFunction(curr, next, header.sortOrder);
        }
        if (!a[param]) return 1;
        if (!b[param]) return -1;
        return sortFunction(a[param], b[param], header.sortOrder);
      });
      setSortedHeader(header);
      setTableData(result);
    }
  };

  return (
    <PageContent>
      <Title>User overview</Title>
      <ComplexTable columns={9}>
        <ComplexTableHeaders
          headers={headers}
          endpoint="placeholder"
          setSortedHeader={(header: SortedTableHeader) => filterData(header)}
          filterByFacets={(param: string, selected: string[]) => filterByFacets(param, selected)}
          allowFilters
          sortedHeader={sortedHeader}
          stickyTop="50px"
          facets={facets}
        />
        {tableData ?
          tableData.map((user, i) => <Row key={user.id} user={user} even={i % 2 === 0} />)
          : <Loader />}
      </ComplexTable>
    </PageContent>
  );
};
