import React, { FunctionComponent } from 'react';
import 'styled-components/macro';
import { ButtonGroup } from '../../ButtonGroup';
import { SmallLoader } from '../../Loader';
import { BoxButton } from '../BoxButton';

interface Props {
  alignRight?: boolean;
  fullWidth?: boolean;
  onClose: () => void;
  closeText?: string;
  onSave?: () => void;
  saveText?: string;
  submitting?: boolean;
  disableSave?: boolean;
  className?: string;
}

export const SaveCloseButtons: FunctionComponent<Props> = ({
  alignRight,
  fullWidth,
  onClose,
  closeText: cancelText = 'Close',
  onSave,
  submitting,
  saveText = 'Save',
  disableSave,
  className,
}) => (
  <ButtonGroup alignRight={alignRight} className={className} fullWidth={fullWidth}>
    <BoxButton inverted onClick={onClose} css={fullWidth ? 'flex: 1' : ''}>
      {cancelText}
    </BoxButton>
    <BoxButton onClick={onSave} css={fullWidth ? 'flex: 1' : ''} disabled={disableSave || submitting}>
      {submitting ? <SmallLoader /> : saveText}
    </BoxButton>
  </ButtonGroup>
);
