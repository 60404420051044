import { useTypedModal } from '@/components/modal/ModalContext';
import { ImagesModal } from '../../ImagesModal';

interface ImagesModalArgs {
    title: string;
    imageState: string[];
    selectOneImage: boolean;
    onImageChange?: (newImages: string[]) => void;
  }

export const useImagesLibraryModal = ({ title, selectOneImage, onImageChange, imageState }: ImagesModalArgs) => useTypedModal(ImagesModal, title, {
  selectedImages: imageState,
  selectOneImage,
  onSelect: (ids: string[]) => {
    if (onImageChange) {
      onImageChange(ids);
    }
  }
});
