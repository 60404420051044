import React from 'react';
import { LabelValuePair } from '@/types';
import { SearchWrapper } from './core/Components';
import { SearchFilter } from './core/SearchFilter';
import { Search } from './core/Search';

interface SearchProps {
  onSearch: (searchTerm?: string) => void;
  onReset: () => void;
}
interface FilteredSearchProps extends SearchProps {
  filterOptions?: LabelValuePair[];
  selectedOption?: string;
  onFilter: (option?: string) => void;
}

export const SearchBar = ({ onSearch, onReset }: SearchProps) => (
  <SearchWrapper>
    <Search onReset={onReset} onSearch={onSearch} />
  </SearchWrapper>
);

export const FilteredSearchBar = (
  { filterOptions, selectedOption, onFilter, onSearch, onReset }: FilteredSearchProps
) => (
  <SearchWrapper>
    <SearchFilter options={filterOptions} selectedType={selectedOption} onSelect={onFilter} />
    <Search onReset={onReset} onSearch={onSearch} />
  </SearchWrapper>
);
