import React from 'react';
import styled from 'styled-components';
import { BusinessAccount } from '@/types';
import { TableCell } from '@/components/shared/Table';
import ReactTooltip from 'react-tooltip';
import { theme } from '@/utils/theme';

export const getBusAccTitle = (businessAccounts: BusinessAccount[]) => {
  if (businessAccounts.length === 0) {
    return '-';
  }
  if (businessAccounts.length === 1) {
    return businessAccounts[0].id.toString();
  }
  return `${businessAccounts[0].id} + ${businessAccounts.length - 1}`;
};

const getAccountText = (account: BusinessAccount) => {
  const text = `${account.id} - ${account.name.trim()}`;
  const maxLength = 30;
  if (text.length <= maxLength) return text;
  return `${text.substr(0, maxLength).trim()}..`;
};

const StyledTooltip = styled(ReactTooltip)`
  &.companiesTooltip{       
    font-size: 16px;
    line-height: 30px;
    filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.24));
    color: ${props => props.theme.marineBlue};
    background-color: ${props => props.theme.white};
    &.show {
      opacity: 1; 
    }
  }  
`;

const TooltipText = styled.div`
  margin: 5px;
`;

// Shift the padding from the outer table cell to the text
// inside, as the tooltip needs to anchor to the top of the
// cell itself.
const StyledTableCell = styled(TableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
`;

const TableCellInner = styled.div`
  padding: 4px 0px;
`;

interface Props {
  accounts: BusinessAccount[];
}
export const BusinessAccounts = ({ accounts } : Props) => {
  const title = getBusAccTitle(accounts);
  const showTooltip = accounts.length > 1;
  const { id } = accounts[0] ?? 'companies-tooltip';
  return (
    <StyledTableCell>
      <TableCellInner data-tip data-for={id}>{title}</TableCellInner>
      {showTooltip && (
      <StyledTooltip
        id={id.toString()}
        effect="solid"
        data-tip={id.toString()}
        arrowColor={theme.white}
        multiline
        type="info"
        className="companiesTooltip"
      >
        {accounts.map(account => <TooltipText key={account.id}>{getAccountText(account)}</TooltipText>)}
      </StyledTooltip>
      )}
    </StyledTableCell>
  );
};
