import React from 'react';
import styled from 'styled-components';
import { useCompanyTypes } from '@/enrollment/services/services';
import { FilteredSearchBar } from '@/components/shared/Search';

const MarginWrapper = styled.div`
  margin-bottom: 5rem;
  @media (max-width: 1023px) {
    margin: 0 16px;
    flex-wrap: wrap;
  }
`;

interface Props {
  selectedType?: string;
  setSelectedType: (newType?: string) => void;
  onSearch: (searchTerm?: string) => void;
  onReset: () => void;
}
export const CompanySearch = ({ selectedType, setSelectedType, onSearch, onReset }: Props) => {
  const { data } = useCompanyTypes();
  const options = data && data.map(o => ({ value: o.typeCode, label: o.description }));
  return (
    <MarginWrapper>
      <FilteredSearchBar
        filterOptions={options}
        selectedOption={selectedType}
        onFilter={setSelectedType}
        onSearch={onSearch}
        onReset={onReset}
      />
    </MarginWrapper>
  );
};
