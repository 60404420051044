import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import {
  BoxButton, ButtonGroup, DatePicker, FormRow, TimePicker
} from '@instech/components';
import { useModalContext } from '@/components/modal/ModalContext';
import moment from 'moment';
import { constructDateTime } from '@/utils/date';
import { validationSchema } from '../utils';
import { ModalLayout, Header } from '../../core/ModalComponents';

const FormFields = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 65px;
`;

interface FormValues {
  date: string | null;
  time: string;
}

interface Props {
  onPublish: (dateTime: string) => void;
  notVisibleBefore: string | undefined;
}
export const SchedulePublishModal = ({ onPublish, notVisibleBefore }: Props) => {
  const { close } = useModalContext();
  const schedueledDate = notVisibleBefore || null;
  const schedueledTime = notVisibleBefore ? moment.utc(notVisibleBefore).format('HH:mm') : '';
  const initialValues = { date: schedueledDate, time: schedueledTime };

  const onSubmit = ({ date, time }: FormValues) => {
    const dateTime = constructDateTime(date, time, true);
    if (dateTime) onPublish(dateTime);
  };

  return (
    <ModalLayout>
      <Header>Scheduled Publish</Header>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, dirty }) => (
          <FormFields>
            <FormRow gutter="34px">
              <DatePicker name="date" label="Date" />
              <TimePicker name="time" label="Time - UTC" />
            </FormRow>
            <ButtonGroup>
              <BoxButton width="164px" inverted onClick={close}>Back</BoxButton>
              <BoxButton width="164px" disabled={!dirty && !notVisibleBefore} onClick={handleSubmit}>Schedule publish</BoxButton>
            </ButtonGroup>
          </FormFields>
        )}
      </Formik>
    </ModalLayout>
  );
};

export const openSchedulePublishModal = ({ onPublish, notVisibleBefore }: Props) => ({
  component: SchedulePublishModal,
  options: {
    size: 'small',
    padding: 0
  },
  args: { onPublish, notVisibleBefore }
});
