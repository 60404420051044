/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent } from 'react';
import { Field, useField } from 'formik';
import {
  FieldLayout, FieldLabel, ErrorMessage, InputField
} from '../core/Components';
import { useFieldId } from '../core/useFieldId';

interface Props {
  name: string;
  label?: string;
  placeholder: string;
  emptyLabel?: boolean;
  noErrors?: boolean;
  useSpellcheck?: boolean;
  className?: string;
  readOnly?: boolean;
}
export const TextField: FunctionComponent<Props> = (
  { name, label = undefined, placeholder, emptyLabel = false, noErrors = false, useSpellcheck = true, className, readOnly, ...fieldProps }
) => {
  const [, meta] = useField(name);
  const { fieldId } = useFieldId(name);

  return (
    <FieldLayout className={className}>
      {(label || emptyLabel) && <FieldLabel as="label" htmlFor={fieldId}>{label}</FieldLabel>}
      <InputField error={meta.touched && meta.error}>
        <Field id={fieldId} name={name} placeholder={placeholder} type="text" spellCheck={useSpellcheck} readOnly={readOnly} {...fieldProps} />
      </InputField>
      {!noErrors && (
        <ErrorMessage>
          {meta.error && meta.touched ? meta.error : ''}
        </ErrorMessage>
      )}
    </FieldLayout>
  );
};
