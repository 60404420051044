import styled from 'styled-components';

interface StyledButtonProps {
  inverted?: boolean;
  width?: string;
  height?: string;
}
export const StyledButton = styled.button<StyledButtonProps>`
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.marineBlue};
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
  cursor: pointer;
  color: ${props => props.inverted ? props.theme.marineBlue : props.theme.white};
  background-color: ${props => props.inverted ? props.theme.white : props.theme.marineBlue};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  min-height: ${props => !props.height && '48px'};
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, width 0.3s ease;

  & svg {
    height:25px;
  }
  :disabled {
    color: ${props => props.inverted ? props.theme.black : props.theme.darkGray};
    background-color: ${props => props.inverted ? props.theme.ultraLightGray : props.theme.mediumGray};
    border-color: ${props => props.inverted ? props.theme.marineBlue50 : 'transparent'};
    cursor: default;
  }
  @media (hover: hover) { 
    &:hover&:not(:disabled) {
      background-color: ${props => props.inverted ? props.theme.white : props.theme.marineBlue75};
      border-color: ${props => props.inverted ? props.theme.white : 'transparent'};
    }; 
  }
`;

export const Container = styled.div<{ padding?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.padding || '10px 20px'};
`;

interface TextContainerProps {
  fontSize?: string;
  boldFont?: boolean;
}
export const TextContainer = styled.span<TextContainerProps>`
  line-height: 16px;
  text-align: center;
  font-size: ${props => props.fontSize ? props.fontSize : '13px'};
  font-weight: ${props => props.boldFont && 'bold'};
`;

export const IconContainer = styled.div<{ start?: string; end?: string; }>`
  padding-right: ${props => props.start && '10px'};
  padding-left: ${props => props.end && '10px'};
  display: flex;
`;
