import React from 'react';
import { useParams, useHistory } from 'react-router';
import styled from 'styled-components';
import { LightboxHandler } from '@instech/components';
import {
  publishAnnouncement, scheduledPublish, useAnnouncement
} from '@/services/announcementsServices';
import { useImagePreviewById } from '@/services/imageServices';
import { Loader } from '@/components/shared/Loader';
import { Operations, Announcement } from '@/types';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { useModalContext } from '@/components/modal/ModalContext';
import moment from 'moment';
import { NewAnnouncementProgress } from '../core/NewAnnouncementProgress';
import { MainColumn, PageFlex } from '../core/Components';
import { openSchedulePublishModal } from './core/SchedulePublishModal';
import {
  CancelButton, PreviousButton, ProgressButtons, PublishButton, PushChangesButton,
  SaveDraftButton, SchedulePublishButton
} from '../core/ProgressButtons';
import { ProgressKey, useProgress } from '../core/ProgressContext';
import { getActualAnnouncementState, useAnnouncementState } from '../core/AnnouncementStateContext';
import { Step } from '../NewAnnouncementPage';
import { openNotificationModal } from './core/NotificationModal';
import { RecipientsPreview } from './RecipientsPreview';
import { ArticlePreview } from './ArticlePreview';
import { CardPreview } from './CardPreview';
import { openConfirmationModal, PushChangesConfirmationModalData } from '../core/ConfirmationModal';

const UpdateContents = styled.div`
  & > * + *  {
    padding-top: 12px;
  }
`;

interface BaseButtonProps {
  draft?: boolean;
  dirty?: boolean;
  onClick: () => any;
}

interface ExtendedButtonProps extends BaseButtonProps {
  operations: Operations;
  duplicate?: boolean;
  scheduled?: boolean;
}

const PublishOrUpdateButton = ({ draft, dirty, operations, duplicate, scheduled, onClick }: ExtendedButtonProps) => {
  if (scheduled) return null;
  if (draft) return <PublishButton width="130px" onClick={onClick} disabled={duplicate || !operations.publish} />;
  return <PushChangesButton width="130px" onClick={onClick} disabled={!dirty || !operations.publish} />;
};

const SaveDraftOrNoButton = ({ draft, dirty, onClick }: BaseButtonProps) => {
  if (draft) return <SaveDraftButton width="130px" onClick={onClick} disabled={!dirty} />;
  return null;
};

const SchedulePublishOrNoButton = ({ draft, dirty, operations, duplicate, scheduled, onClick }: ExtendedButtonProps) => {
  if (draft || scheduled) return <SchedulePublishButton inverted width="130px" onClick={onClick} disabled={!operations.publish} />;
  return null;
};

const isScheduled = (announcement: Announcement) => {
  const { published } = announcement;
  const futureScheduele = (announcement.notVisibleBefore && moment(announcement.notVisibleBefore) > moment()) === true;
  return published && futureScheduele;
};

interface Props {
  steps: Step[];
  stepNum: number;
  handleCancel: (val?: any) => void;
}
export const PreviewPublish = ({ steps, stepNum, handleCancel }: Props) => {
  const history = useHistory();
  const { announcementId }: { announcementId: string } = useParams();
  const { open, close } = useModalContext();
  const { data: initialUpdate, saveAnnouncement } = useAnnouncement(announcementId);
  const { previous, jump } = useProgress();
  const { state: currentUpdate, dirty } = useAnnouncementState();

  if (!initialUpdate) return <Loader />;

  const actualAnnouncement = getActualAnnouncementState(initialUpdate, currentUpdate);
  const scheduled = isScheduled(actualAnnouncement);

  const jumpStep = (step: number) => {
    const target = steps[step]?.route;
    if (target) {
      jump(target as ProgressKey);
    }
  };

  const returnToAnnouncementsPage = () => {
    history.push('/announcements/');
  };

  const showNotificationModal = (scheduledAnnouncement?: Announcement) => {
    const announcement = scheduledAnnouncement ?? actualAnnouncement;
    open(openNotificationModal({ announcement, returnToAnnouncementsPage }));
  };

  const onScheduledPublish = async (dateTime: string) => {
    const newData = { ...actualAnnouncement, notVisibleBefore: dateTime };
    const scheduledUpdate = await scheduledPublish(newData, saveAnnouncement);
    close();
    showNotificationModal(scheduledUpdate);
  };

  const schedulePublishModal = openSchedulePublishModal({ onPublish: onScheduledPublish, notVisibleBefore: actualAnnouncement.notVisibleBefore });
  const showSchedulePublishModal = () => open(schedulePublishModal);

  const handleSaveDraft = async () => {
    await saveAnnouncement(actualAnnouncement);
    returnToAnnouncementsPage();
  };

  // publish update and redirect to Updates page
  const handleSubmit = async () => {
    if (!actualAnnouncement.published) {
      await saveAnnouncement(actualAnnouncement);
      await publishAnnouncement(actualAnnouncement.id);
      showNotificationModal();
    } else {
      open(openConfirmationModal({ title: 'Push Changes' },
        { confirmCallback: () => saveAnnouncement(actualAnnouncement), ...PushChangesConfirmationModalData }));
    }
  };

  const onEditRecipients = () => jumpStep(0);

  const onEditContents = () => {
    jump(steps[1].route as ProgressKey);
  };

  return (
    <LightboxHandler imageLoader={useImagePreviewById}>
      <PageFlex>
        <MainColumn>
          <NewAnnouncementProgress steps={steps} currentStep={stepNum} />
          <UpdateContents>
            <RecipientsPreview recipients={actualAnnouncement.recipientStructure} onEdit={onEditRecipients} />
            <CardPreview announcement={actualAnnouncement} onEdit={onEditContents} />
            <ArticlePreview announcement={actualAnnouncement} onEdit={onEditContents} />
          </UpdateContents>
          <ButtonGroup alignRight marginTop="24px">
            <PreviousButton width="130px" onClick={previous} />
            <SaveDraftOrNoButton
              draft={!actualAnnouncement.published}
              onClick={handleSaveDraft}
              dirty={dirty}
            />
            <SchedulePublishOrNoButton
              draft={!actualAnnouncement.published}
              operations={actualAnnouncement.operations}
              scheduled={scheduled}
              onClick={showSchedulePublishModal}
            />
            <PublishOrUpdateButton
              draft={!actualAnnouncement.published}
              duplicate={actualAnnouncement.isDuplicate}
              scheduled={scheduled}
              operations={actualAnnouncement.operations}
              onClick={handleSubmit}
              dirty={dirty}
            />
          </ButtonGroup>
        </MainColumn>
        <ProgressButtons>
          <PublishOrUpdateButton
            draft={!actualAnnouncement.published}
            duplicate={actualAnnouncement.isDuplicate}
            scheduled={scheduled}
            operations={actualAnnouncement.operations}
            onClick={handleSubmit}
            dirty={dirty}
          />
          <PreviousButton width="130px" onClick={previous} />
          <CancelButton width="130px" onClick={() => handleCancel()} />
          <SchedulePublishOrNoButton
            draft={!actualAnnouncement.published}
            scheduled={scheduled}
            operations={actualAnnouncement.operations}
            onClick={showSchedulePublishModal}
          />
          <SaveDraftOrNoButton
            draft={!actualAnnouncement.published}
            onClick={handleSaveDraft}
            dirty={dirty}
          />
        </ProgressButtons>
      </PageFlex>
    </LightboxHandler>
  );
};
