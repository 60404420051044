import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Company } from '@/types';
import {
  AccountBox, Assignment, Business, Place, Subtitles, ContactMail
} from '@instech/icons';
import { BusinessAccounts } from './BusinessAccounts';
import { CellContent, CellTitle } from './Components';

const Pane = styled.div`
  background-color: ${props => props.theme.white};
  box-shadow: 0 1px 6px 0 rgba(173,173,173,0.5);
  padding: 31px 17px;
  margin-bottom: 47px;
`;

const GridWrapper = styled.div`
  display: flex;
`;

const Grid = styled.div`
  flex: 3;
  display: grid;
  // 20px icon column and 1fr content column, repeat 3 times
  grid-template-columns: repeat(3, 20px 1fr);
  column-gap: 8px;
  row-gap: 24px;
`;

const Whitespace = styled.div`
  flex: 1.2;
  @media (max-width: 1000px) {
    flex: 0.5
  }
  @media (max-width: 800px) {
    flex: 0
  }
`;

const Cell = styled.div`
  & svg {
    height: 16px;
    color: ${props => props.theme.blue};
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${props => props.theme.marineBlue50};
  margin: 24px 0px;
`;

interface Props { company: Company }
const Info: FunctionComponent<Props> = ({ company }) => (
  <GridWrapper>
    <Grid>
      <Cell><AccountBox /></Cell>
      <Cell>
        <CellTitle>Name</CellTitle>
        <CellContent>{company.name}</CellContent>
      </Cell>
      <Cell><Assignment /></Cell>
      <Cell>
        <CellTitle>Type</CellTitle>
        <CellContent>{company.type}</CellContent>
      </Cell>
      <Cell><Subtitles /></Cell>
      <Cell>
        <CellTitle>CRM Number</CellTitle>
        <CellContent>{company.companyNumber}</CellContent>
      </Cell>
      <Cell><Business /></Cell>
      <Cell>
        <CellTitle>City</CellTitle>
        <CellContent>{company.city}</CellContent>
      </Cell>
      <Cell><Place /></Cell>
      <Cell>
        <CellTitle>Country</CellTitle>
        <CellContent>{company.country}</CellContent>
      </Cell>
      <Cell><ContactMail /></Cell>
      <Cell>
        <CellTitle>Email</CellTitle>
        <CellContent>{company.email}</CellContent>
      </Cell>
    </Grid>
    <Whitespace />
  </GridWrapper>
);

export const CompanyInfo: FunctionComponent<Props> = ({ company }) => (
  <Pane>
    <Info company={company} />
    {company.businessAccounts.length > 0 && (
      <>
        <Divider />
        <BusinessAccounts businessAccounts={company.businessAccounts} />
      </>
    )}
  </Pane>
);
