import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

/**
 * Hook to redirect to a different route.
 * Useful when a new render cycle is needed before redirecting.
 */
export const useRedirect = (to: string) => {
  const history = useHistory();
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);

  useEffect(() => {
    if (shouldRedirect) {
      setShouldRedirect(false);
      history.push(to);
    }
  }, [shouldRedirect, history, to]);

  return setShouldRedirect;
};
