import React from 'react';
import styled from 'styled-components';
import { ImageBorder } from './ImageBorder';
import { ImageCardLoadError } from './ImageCardLoadError';

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: ${props => props.theme.flatWhite};
  /* Styling for image alt text */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: ${props => props.theme.marineBlue};
`;

interface ViewImageProps {
  url: string;
  alt: string;
  width?: string;
  height?: string;
  darkBorder?: boolean;
  noBorder?: boolean;
  isSelected?: boolean;
  onClick?: any;
  disableClick?: any;
  className?: string;
}
export const Image = (props: ViewImageProps) => {
  if (!props.url) {
    return <ImageCardLoadError {...props} />;
  }
  return (
    <ImageBorder
      className={props.className}
      width={props.width}
      height={props.height}
      darkBorder={props.darkBorder}
      noBorder={props.noBorder}
      isSelected={props.isSelected}
      onClick={props.onClick}
      disabled={props.disableClick}
    >
      <StyledImage src={props.url} alt={props.alt || ''} title={props.alt} />
    </ImageBorder>
  );
};
