import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Cry } from '@instech/icons';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { BoxButton } from '@/components/shared/BoxButton/BoxButton';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.marineBlue};
  svg {
    height: 100px;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto;
  h2 {
    margin: 0.3rem auto;
  }
  p {
    margin: 0.3rem auto;
  }
`;

export const DefaultErrorMessage = ({ message, reset, hideDismissButton }) => (
  <Column>
    <Cry />
    <Text>
      <h2>That didn&apos;t go well</h2>
      <p>If the problem persists, contact support</p>
    </Text>
    { !hideDismissButton && (
      <ButtonGroup justify="flex-end">
        <BoxButton inverted onClick={reset}>Dismiss</BoxButton>
      </ButtonGroup>
    )}

  </Column>
);

DefaultErrorMessage.propTypes = {
  message: PropTypes.string,
  reset: PropTypes.func,
  hideDismissButton: PropTypes.bool
};
