import React from 'react';
import styled from 'styled-components';

const InfoText = styled.div`
  text-align: center;
  padding: 30px 16px;
  font-size: 24px;
`;

export const NoImagesUploaded = () => (
  <InfoText>No images uploaded yet</InfoText>
);
