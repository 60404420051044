import {
  Announcement,
  Dictionary,
  Provider, UpdateApplication, UpdateApplicationRole, UpdateUserInRole
} from '@/types';
import { FormValue } from './SelectRecipients';

const getSelected = (items: { selected: boolean, displayName: string }[]) => (
  items.filter(i => i.selected).map(i => i.displayName)
);

const getSelectedRolesByApplication = (applications: UpdateApplication[]) => {
  const selectedRolesByApplication = applications
    .filter(a => a.selected)
    .flatMap(a => ({ key: a.displayName, value: getSelected(a.roles) }))
    .reduce((obj, next) => ({
      ...obj,
      [next.key]: next.value
    }), {} as Dictionary<string[]>);

  return selectedRolesByApplication;
};

export const getInitialValues = (update: Announcement) => {
  const selectedProviders = getSelected(update.recipientStructure.providers);
  const selectedApplications = getSelected(update.recipientStructure.applications);
  const selectedRolesByApplication = getSelectedRolesByApplication(update.recipientStructure.applications);
  const initialValues: FormValue = { providers: selectedProviders, applications: selectedApplications, ...selectedRolesByApplication };

  return initialValues;
};

/**
 * Iterates trough the Update and updates the 'selected' flag according
 * to the values in the form.
 */
export const calcualteRecipients = (values: FormValue, announcement: Announcement) => {
  const selectProviders = (provider: Provider) => {
    const isSelected = values.providers.includes(provider.displayName);
    return { ...provider, selected: isSelected };
  };

  const providers = announcement.recipientStructure.providers.map(selectProviders);
  const selectedProviderIds = providers.filter(x => x.selected).map(x => x.providerId);

  const selectUser = (user: UpdateUserInRole) => ({ ...user, selected: selectedProviderIds.includes(user.providerId) });

  const selectRole = (role: UpdateApplicationRole, application: UpdateApplication) => {
    const isRoleSelected = values[application.displayName]?.includes(role.displayName);

    const users = isRoleSelected ? role.users.map(selectUser) : role.users;

    return { ...role, selected: isRoleSelected, users };
  };

  const selectApplications = (application: UpdateApplication) => {
    const isSelected = values.applications.includes(application.displayName);

    const roles = application.roles.map(role => selectRole(role, application));

    return { ...application, roles, selected: isSelected };
  };

  const applications = announcement.recipientStructure.applications.map(selectApplications);

  const selectedApplications = applications.filter(x => x.selected);
  const resultedSelectedSubjectsIds = new Set<string>();

  selectedApplications.forEach(x => {
    const selectedRoles = x.roles.filter(r => r.selected);
    selectedRoles.map(sr => sr.users
      .filter(u => selectedProviderIds.some(p => p === u.providerId))
      .map(user => user.subjectId))
      .flatMap(subjectId => subjectId)
      .forEach(sId => resultedSelectedSubjectsIds.add(sId));
  });

  const recipientStructure = { ...announcement.recipientStructure, providers, applications, selectedCount: resultedSelectedSubjectsIds.size };
  return recipientStructure;
};

/**
 * First checks that at least one provider and at least one application is selected.
 * Then checks that for each application that is selected, at leaset one role in that application is selected.
 */
export const hasValidValues = (values: FormValue) => {
  const keys = Object.keys(values);
  const hasApplications = keys.includes('applications') && values.applications.length > 0;
  const hasProviders = keys.includes('providers') && values.providers.length > 0;

  if (!hasApplications || !hasProviders) return false;

  const rolesByApplication = Object.entries(values).filter(([key]) => key !== 'applications' && key !== 'providers');

  if (rolesByApplication.length === 0) return false;
  if (rolesByApplication.length !== values.applications.length) return false;

  const hasRolesInEachApplication = rolesByApplication.every(([, roles]) => roles.length > 0);

  return hasRolesInEachApplication;
};
