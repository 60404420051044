import React, { FunctionComponent as FC } from 'react';
import { ApplicationRole } from '@/types';
import styled from 'styled-components';
import { PaneButton } from './PaneButton';
import { NavButtonSlim } from '@/components/shared/BoxButton';
import { routes } from '../../NewAnnouncementPage/utils';
import { Add } from '@instech/icons';
import { SideColumn } from '@/components/layout/Page';

const Pane = styled.div`
  background-color: ${props => props.theme.white};
  margin-bottom: 40px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0.06);
`;

const PaneInner = styled.div`
  padding: 32px 24px 40px;
  display: flex;
  justify-content: space-between;
`;

const PageTitle = styled.h1`
  font-weight: normal;
  font-size: 40px;
  line-height: 40px;
  margin: 0px;
  color: ${props => props.theme.marineBlue};
`;

const ButtonRow = styled.div`
  display: flex;
`;

interface Props {
    applications: ApplicationRole[];
    setActiveTab: (application: ApplicationRole) => any;
    activeTab: ApplicationRole;
}

export const CategoryPane: FC<Props> = ({ applications, activeTab, setActiveTab }) => (
  <Pane>
    <PaneInner>
      <PageTitle>Updates</PageTitle>
      <SideColumn>
        <NavButtonSlim width="100%" to={routes.newAnnouncement} startIcon={<Add />}>
          New Update
        </NavButtonSlim>
      </SideColumn>
    </PaneInner>
    <ButtonRow>
      {applications.map(application => (
        <PaneButton
          key={`pane-button ${application.displayName}`}
          label={application.displayName}
          active={activeTab.name === application.name}
          onClick={() => setActiveTab(application)}
        />
      ))}
    </ButtonRow>
  </Pane>
);
