import React from 'react';
import styled, { css } from 'styled-components';
import { FieldLabel, FieldLayout } from '../Form/core/Components';

const CheckboxLabel = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
`;

const Padding = styled.div<{ noPadding?: boolean }>`
  padding: ${props => !props.noPadding && '10px'};
`;

interface CheckboxProps {
  readonly selected: boolean;
  readonly disabled?: boolean;
}
export const Checkbox = styled.div<CheckboxProps>`
  position: relative;
  height: 20px;
  width: 20px;
  background: ${props => props.disabled ? props.theme.lightGray : props.theme.white};
  border: 1px solid ${props => props.disabled ? props.theme.mediumGray : props.theme.border.gray};
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
  box-sizing: border-box;
  
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 4px;
    left: 6px;
    border: 1px solid transparent;
    border-bottom-color: ${props => props.disabled ? props.theme.lightGray : props.theme.white};
    border-right-color: ${props => props.disabled ? props.theme.lightGray : props.theme.white};
    transform: rotate(45deg);
  }
  
  ${props => props.selected && css`
    background: ${props.disabled ? props.theme.lightGray : props.theme.marineBlue};
    border-color: ${props.disabled ? props.theme.mediumGray : props.theme.marineBlue};
    ::after {
      width: 5px;
      height: 13px;
      border-bottom-color: ${props.theme.white};
      border-right-color: ${props.theme.white};
    }
  `}
`;

interface Props {
  selected: boolean;
  disabled?: boolean;
  onClick: () => any;
  label?: string;
  rightLabel?: string;
}
export const FormlessCheckbox = ({ selected, disabled, onClick, label, rightLabel }: Props) => (
  <FieldLayout>
    {label && <FieldLabel as="label" onClick={onClick}>{label}</FieldLabel>}
    <CheckboxLabel disabled={disabled} onClick={onClick}>
      <Padding>
        <Checkbox role="checkbox" selected={selected} disabled={disabled} />
      </Padding>
      {rightLabel && <span>{rightLabel}</span>}
    </CheckboxLabel>
  </FieldLayout>
);
