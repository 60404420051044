import { useModalContext } from '@/components/modal/ModalContext';
import { BoxButtonSlim } from '@/components/shared/BoxButton';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { deleteDraft } from '@/services/announcementsServices';
import React from 'react';
import styled, { css } from 'styled-components';

const Text = styled.p<{ bold?: boolean }>`
  font-size: 16px;
  ${props => props.bold && css`
    font-weight: bold;  
  `};
  text-align: center;
  margin-top: 0px;
  color: ${props => props.theme.marineBlue};
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 80px;
  justify-content: center;
`;

interface Props {
  announcementId: string;
  onDraftDelete: () => void;
}
const ConfirmDeleteDraftModal: React.FC<Props> = ({ announcementId, onDraftDelete }) => {
  const { close: closeModal } = useModalContext();

  const handleDeleteDraft = async () => {
    await deleteDraft(announcementId);
    closeModal();
    onDraftDelete();
  };

  return (
    <>
      <Text bold>Do you wish to delete the draft?</Text>
      <Text>You will not be able to restore it later</Text>
      <StyledButtonGroup>
        <BoxButtonSlim width="164px" height="40px" inverted onClick={closeModal}>Cancel</BoxButtonSlim>
        <BoxButtonSlim width="164px" height="40px" onClick={handleDeleteDraft}>Delete draft</BoxButtonSlim>
      </StyledButtonGroup>
    </>
  );
};

export const openConfirmDeleteDraftModal = (announcementId: string, onDraftDelete: () => void) => ({
  component: ConfirmDeleteDraftModal,
  options: {
    title: 'Delete Draft',
    size: 'small'
  },
  args: { announcementId, onDraftDelete }
});
