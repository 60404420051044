/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Add, FloppyDisk } from '@instech/icons';
import { BoxButton } from '../BoxButton/BoxButton';
import { LinkButton } from '../LinkButton/LinkButton';
import { SmallLoader } from '../Loader';

const ButtonWrapper = styled.div`
  margin-top: ${props => props.marginTop || '0px'};
`;

export const AddButton = ({ label = 'Add', onClick, inverted = false }) => (
  <BoxButton startIcon={<Add />} onClick={onClick} inverted={inverted}>{label}</BoxButton>
);
AddButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  inverted: PropTypes.bool
};

// Color on SmallLoader is a workaround to make it follow the button text color,
// see Loader.tsx for more
export const SaveButton = (
  { label = 'Save', title = 'Save', onClick, disabled, isSubmitting, width, padding, noIcon, fontSize }
) => (
  <BoxButton
    startIcon={!isSubmitting && !noIcon && <FloppyDisk />}
    disabled={!isSubmitting && disabled}
    onClick={!isSubmitting ? onClick : null}
    width={width}
    title={title}
    padding={padding}
    fontSize={fontSize}
  >
    {isSubmitting ? <SmallLoader color="inherit" /> : label}
  </BoxButton>
);
SaveButton.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  width: PropTypes.string,
  padding: PropTypes.string,
  noIcon: PropTypes.bool,
  fontSize: PropTypes.string
};

export const CloseButton = ({ label = 'Close', onClick, disabled }) => (
  <LinkButton height="47px" onClick={onClick} disabled={disabled}>{label}</LinkButton>
);
CloseButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export const ToggleInlineButton = ({ addLabel, onClick, marginTop }) => (
  <ButtonWrapper marginTop={marginTop}>
    <LinkButton startIcon={<Add />} onClick={onClick} height="38px">{addLabel}</LinkButton>
  </ButtonWrapper>
);
ToggleInlineButton.propTypes = {
  addLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  marginTop: PropTypes.string
};
