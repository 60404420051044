import { Company, Guid } from '@/types';
import useSWR from 'swr';
import client from './client';

export const useCompanies = () => {
  const requestUrl = '/company';
  return useSWR<Company[]>(requestUrl);
};

export const useCompany = (companyId: string) => {
  const requestUrl = `/company/${companyId}`;
  return useSWR<Company>(requestUrl);
};

export const useCompanyByExternalId = (companyId: Guid) => {
  const requestUrl = `/company/external/${companyId}`;
  return useSWR<Company>(requestUrl);
};

export const useCompanyExistsCheck = (companyId: string) => {
  const requestUrl = `/company/${companyId}/exists`;
  return useSWR<Boolean>(requestUrl);
};

/**
 * Get the internal id of an existing company by mdmExternalId. Return undefined if it
 * does not exist in the internal system
 */
export const getExistingCompanyIdAsync = async (mdmExternalId: Guid): Promise<number | undefined> => {
  const allCompanies = await client.get<Company[]>('/company');

  const existingCompany = allCompanies.data?.find(c => c.mdmExternalId === mdmExternalId);
  return existingCompany?.id;
};

export const enrollCompanyAsync = async (companyId: Guid): Promise<Company> => {
  const requestUrl = `/externalcompany/${companyId}`;
  const result = await client.post<Company>(requestUrl);
  return result.data;
};

export interface CreateCompanyModel {
  mdmExternalId: Guid;
  name: string;
  type: string;
  companyNumber: number;
  city?: string;
  country?: string;
  email: string;
}
export const createCompanyAsync = async (companyModel: CreateCompanyModel): Promise<number> => {
  const requestUrl = '/company';
  const existingCompanyId = await getExistingCompanyIdAsync(companyModel.mdmExternalId);
  if (existingCompanyId) {
    return existingCompanyId;
  }
  const result = await client.post<Company>(requestUrl, companyModel);
  return result.data.id;
};
