import moment, { Moment } from 'moment';

// Date format used for input formatting
export const friendlyDateFormat = 'D MMM YYYY';
// Date format and function used to format readonly text, such as
// in the report. Should not be used to format inside input fields.
export const friendlyDateDotted = 'D MMM. YYYY';
const friendlyMonthDotted = 'D MMM.';

// Returns UTC "([Today] | {Weekday} | {Day Mon. Yr}) - HH:mm" depending
// on time between date and user's current UTC timestamp
const contextualTimestamp = (date: Moment, now: Moment) => {
  const sameDay = now.isSame(date, 'day');
  const sameWeek = now.isoWeek() === date.isoWeek();

  if (sameDay) return date.format('[Today] - HH:mm');
  if (sameWeek) return date.format('dddd - HH:mm');
  if (date.month() === 4) return date.format('DD MMM YYYY - HH:mm');
  return date.format('DD MMM. YY - HH:mm');
};

/**
 * Takes a `date` string and returns a user friendly date & time in a
 * UTC contextual `(Today | {Weekday} | {Day Mon. Yr}) - HH:mm` format
 */
export const getFriendlyUTCTimestamp = (date?: string | null) => {
  if (!date) return '-';
  const momentDate = moment.utc(date, moment.ISO_8601);
  const momentNow = moment.utc(moment(), moment.ISO_8601);
  return contextualTimestamp(momentDate, momentNow);
};

/**
 * Takes a `date` string and returns a user friendly date & time in a
 * local contextual `(Today | {Weekday} | {Day Mon. Yr}) - HH:mm` format
 */
export const getFriendlyLocalTimestamp = (date?: string | null) => {
  if (!date) return '-';
  const momentDate = moment(date, moment.ISO_8601).local();
  const momentNow = moment().local();
  return contextualTimestamp(momentDate, momentNow);
};

/**
 * Takes a `date` string and returns a user friendly date
 * in a UTC-localized `D MMM. YYYY` format
 */
export const getFriendlyDate = (date?: string | null, format = friendlyDateDotted) => {
  if (!date) return '-';
  const momentDate = moment.utc(date, moment.ISO_8601);
  // May should not have a dot after - and moment counts months from 0
  if (momentDate.month() === 4) {
    if (format === friendlyDateDotted) return momentDate.format('D MMM YYYY');
    if (format === friendlyMonthDotted) return momentDate.format('D MMM');
  }
  return momentDate.format(format);
};
