import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
`;

const NavEntry = styled(NavLink)`
  margin-top: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.5px;
  padding: 10px 32px;
  text-align: center;
  &.active {
    background-color:  ${props => props.theme.whiteBlue};
    color:  ${props => props.theme.marineBlue};
  }
`;

export const Navigation: FunctionComponent = () =>
  (
    <Layout>
      <NavEntry to="/companies">COMPANIES</NavEntry>
      <NavEntry to="/users">USERS</NavEntry>
      <NavEntry to="/add-company">ADD COMPANY</NavEntry>
      <NavEntry to="/announcements">ANNOUNCEMENTS</NavEntry>
    </Layout>
  );
