import React from 'react';
import { RecipientStructure, UpdateApplication } from '@/types';
import styled from 'styled-components';
import { Pane } from '@instech/components';
import { TitleAndEdit } from './core/Components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
`;

const Heading = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: ${props => props.theme.marineBlue};
`;

const RecipientsRow = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 4px;
`;

const RecipientBox = styled.div`
  background-color: ${props => props.theme.green50};
  border-radius: 2px;
  color: ${props => props.theme.marineBlue};
  font-size: 14px;
  padding: 6px 16px;
`;

const Application = ({ application }: { application: UpdateApplication }) => {
  const roles = application.roles.filter(r => r.selected);
  return (
    <div>
      <Heading>{application.displayName}</Heading>
      <RecipientsRow>
        {roles.map(r => <RecipientBox key={r.name}>{r.displayName}</RecipientBox>)}
      </RecipientsRow>
    </div>
  );
};

interface Props {
  recipients: RecipientStructure;
  onEdit: () => void;
}
export const RecipientsPreview = ({ recipients, onEdit }: Props) => {
  const providers = recipients.providers.filter(p => p.selected);
  const applications = recipients.applications.filter(a => a.selected);

  const title = `Recipients - ${recipients.selectedCount}`;
  const PaneTitle = <TitleAndEdit title={title} onEdit={onEdit} padding="0px" />;

  return (
    <div>
      <Pane title={PaneTitle} color="green">
        <Wrapper>
          <div>
            <Heading>External / Internal</Heading>
            <RecipientsRow>
              {providers.map(p => <RecipientBox key={p.displayName}>{p.displayName}</RecipientBox>)}
            </RecipientsRow>
          </div>
          {applications.map(a => <Application key={a.name} application={a} />)}
        </Wrapper>
      </Pane>
    </div>
  );
};
