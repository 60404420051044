import React from 'react';
import { Route, Switch } from 'react-router';
import { RootRouting } from './authentication';
import { Page } from './components/layout/Page';
import { CompaniesPage } from './components/pages/CompaniesPage';
import { CompanyPage } from './components/pages/CompanyPage';
import { UsersPage } from './components/pages/UsersPage';
import { AnnouncementsPage } from './components/pages/AnnouncementsPage';
import { NewAnnouncementPage } from './components/pages/NewAnnouncementPage';
import { EnrollmentPage } from './enrollment';
import { routes } from './components/pages/NewAnnouncementPage/utils';

const Routes = () => {
  const newUpdateRoutes = Object.values(routes);
  return (
    <Switch>
      <Route exact path="/companies">
        <CompaniesPage />
      </Route>
      <Route path="/companies/:companyId">
        <CompanyPage />
      </Route>
      <Route path="/users">
        <UsersPage />
      </Route>
      <Route path="/add-company">
        <EnrollmentPage />
      </Route>
      <Route exact path="/announcements">
        <AnnouncementsPage />
      </Route>
      <Route exact path={newUpdateRoutes}>
        <NewAnnouncementPage />
      </Route>
    </Switch>
  );
};

export const App = () => (
  <RootRouting>
    <Page>
      <Routes />
    </Page>
  </RootRouting>
);
