import React from 'react';
import { BoxButton } from '@/components/shared/BoxButton/BoxButton';
import { InfoCircle } from '@instech/icons';
import styled from 'styled-components';

const Container = styled.div`
  padding: 32px;
  margin-top: 32px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.marineBlue};
`;

const Text = styled.div`
  font-size: 18px;
`;

interface EnrollActionProps {
  onClick: () => Promise<void>
}
export const EnrollAction = ({ onClick }: EnrollActionProps) => (
  <Container>
    <InfoCircle width={40} height={40} />
    <Text>
      This company is not yet added to the Etuity Platform
    </Text>
    <BoxButton onClick={onClick}>Enroll company</BoxButton>
  </Container>
);
