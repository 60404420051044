import React, { FunctionComponent as FC } from 'react';
import styled, { css } from 'styled-components';

interface StyledProps {
  active?: boolean;
}
const StyledButton = styled.button<StyledProps>`
  background: transparent;
  width: 160px;
  padding: 12px 12px 4px;
  border: none;
  border-bottom: 3px solid transparent;
  color: ${props => props.theme.marineBlue};
  box-sizing: border-box;
  cursor: pointer;
  ${props => props.active && css`
    font-weight: bold;
    border-bottom: 3px solid ${props.theme.green};
  `};
  @media (hover: hover) { 
    &:hover&:not(:disabled) {
      background: transparent;
    }
  }
`;

interface Props {
  label: string;
  onClick?: () => any;
  active?: boolean;
}
export const PaneButton: FC<Props> = ({ label, onClick, active }) => (
  <StyledButton onClick={onClick} active={active}>
    {label}
  </StyledButton>
);
