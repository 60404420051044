import { CompanyInfo } from '@/components/shared/CompanyInfo/CompanyInfo';
import { CompanyTitle } from '@/components/shared/CompanyInfo/Components';
import { Loader } from '@/components/shared/Loader';
import { useExternalCompany } from '@/enrollment/services/services';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { enrollCompanyAsync } from '@/services/companiesService';
import { TopSection } from '@/enrollment/core/TopSection';
import { AddCompanyProgress } from '@/enrollment/core/AddCompanyProgress';
import { EnrollAction } from './EnrollAction';
import { ContactsList } from './ContactsList';

interface Props {
  companyId: string
}
const CreateCompanyMessage = ({ companyId }: Props) => {
  const [submitError, setSubmitError] = useState<Error | undefined>(undefined);
  const history = useHistory();

  const onSubmit = async () => {
    try {
      const result = await enrollCompanyAsync(companyId);
      history.push(`/add-company/add-portal/${result.mdmExternalId}`);
    } catch (err) {
      setSubmitError(err);
    }
  };

  if (submitError) {
    return <div>{submitError.message}</div>;
  }

  return (
    <EnrollAction onClick={onSubmit} />
  );
};

export const AddCompanyStep = () => {
  const { companyId } = useParams() as any;
  const { data: company, error } = useExternalCompany(companyId);

  if (!company || error) {
    return <Loader />;
  }

  return (
    <div>
      <TopSection>
        <AddCompanyProgress currentStep={1} />
      </TopSection>
      <CompanyTitle>{company.name}</CompanyTitle>
      <CompanyInfo company={company} />
      <ContactsList companyNumber={company.companyNumber} />
      <CreateCompanyMessage companyId={company.mdmExternalId} />
    </div>
  );
};
