import React, { FunctionComponent } from 'react';
import { Field, useField } from 'formik';
import {
  FieldLayout, FieldLabel, ErrorMessage, InputFieldArea
} from '../core/Components';

interface Props {
  name: string;
  label?: string;
  placeholder: string;
  noErrors?: boolean;
  useSpellcheck?: boolean;
  className?: string;
}
export const TextArea: FunctionComponent<Props> = (
  { name, label, placeholder, noErrors, useSpellcheck = true, className }
) => {
  const [, meta] = useField(name);

  return (
    <FieldLayout className={className}>
      {label && <FieldLabel as="label" htmlFor={name}>{label}</FieldLabel>}
      <InputFieldArea error={meta.touched && meta.error}>
        <Field id={label + name} name={name} placeholder={placeholder} as="textarea" rows="2" type="text" spellCheck={useSpellcheck} />
      </InputFieldArea>
      {!noErrors && (
        <ErrorMessage>
          {meta.error && meta.touched ? meta.error : ''}
        </ErrorMessage>
      )}
    </FieldLayout>
  );
};
