import React from 'react';
import { useAccount } from 'its-js-authentication';
import { useLocation } from 'react-router';
import { Location } from 'history';

interface LocationState {
  from: {
    pathname: string;
  };
}

const getRedirectRoute = (location: Location<LocationState>) => {
  if (location.state && location.state.from) return location.state.from.pathname;
  return '/';
};

export const LoginPage = () => {
  const { login } = useAccount();
  const location = useLocation();
  const route = getRedirectRoute(location);
  const options = { state: { route } };
  login(options);

  return <div>You will now be logged in!</div>;
};
