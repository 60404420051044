// Yup has some ${curly} syntax it uses internally when parsing text,
// that shouldn't be read as a template literal in this file.
/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */

import * as Yup from 'yup';
import { maxFileSize, maxFileSizeString } from './file';

export const stringRequired = (message = 'Required') => Yup.string()
  .default('')
  .nullable()
  .trim()
  .required(message);

export const stringRequiredMaxLength = (maxLength = 60, message = 'Required') => Yup.string()
  .default('')
  .nullable()
  .trim()
  .max(maxLength, 'Cannot be longer than ${max} characters')
  .required(message);

export const stringMaxLength = (maxLength = 60) => (
  Yup.string()
    .default('')
    .nullable()
    .trim()
    .max(maxLength, 'Cannot be longer than ${max} characters')
);

const supportedImageTypes = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png'
];
export const imageRequired = () => Yup.mixed()
  .test('fileSize', `File size is too large. Max size is ${maxFileSizeString}`, value => value.size <= maxFileSize)
  .test('fileSizeMin', 'File size is too small.', value => value.size > 0)
  .test('fileType', 'Unsupported File Format', value => supportedImageTypes.includes(value.type));

const auth0EmailAllowedSpecialCharacters = "!#$'^`~+._-";
const auth0EmailMessage = `Invalid email address. It can only contain alphameric characters and the following special characters: ${auth0EmailAllowedSpecialCharacters.split('').join(' ')}`;
export const auth0Email = () => Yup.string()
  .matches(
    new RegExp(`^[\\w${auth0EmailAllowedSpecialCharacters}]+@\\w+([.-]?\\w+)*(\\.\\w{2,9})+$`),
    auth0EmailMessage
  );
