import styled, { css } from 'styled-components';

interface FormRowProps {
  wrap?: boolean;
  marginBottom?: string;
  gutter?: string;
  indented?: boolean;
}
export const FormRow = styled.div<FormRowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: ${props => props.wrap ? 'wrap' : 'nowrap'};
  margin-bottom: ${props => props.marginBottom || '0'};
  box-sizing: border-box;

  ${props => props.gutter && css`
    margin-left: ${`calc(-${props.gutter} / 2)`};
    margin-right: ${`calc(-${props.gutter} / 2)`};

    & > * {
      padding-left: ${`calc(${props.gutter} / 2)`};
      padding-right: ${`calc(${props.gutter} / 2)`};
    }
  `}

  ${props => props.indented && css`
    padding: 20px 10px 10px;
    margin: 0px;
    margin-top: 5px;
    background: ${props.theme.flatWhite};
  `};
`;

interface FormFieldProps {
  span?: number;
  width?: string;
}
export const FormField = styled.div<FormFieldProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: ${props => props.span ? `${(100 / 12) * props.span}%` : '100%'};

  ${props => props.width && css`
    width: ${props.width};
    box-sizing: content-box;
    flex-shrink: 0;
  `}
`;

export const FormGap = styled(FormField)`
  height: 0;
  padding: 0px;
`;
