import { PortalSelector } from 'insify-remote-component-loader';
import { useAccount } from 'its-js-authentication';
import React from 'react';

export const PortalSelectorLoader = () => {
  const account = useAccount();

  return (
    <PortalSelector
      account={account}
      portal={{ name: 'InsifyWebAdmin', displayName: 'Onboarding' }}
    />
  );
};
