import { HeaderWithoutNav } from '@/components/layout/Header/Header';
import { BoxButton } from '@/components/shared/BoxButton/BoxButton';
import { useHasScrolled } from '@/hooks/useHasScrolled';
import { useAccount } from 'its-js-authentication';
import React from 'react';
import styled from 'styled-components';

const errorMessage = `
You do not have the correct permissions for this application.
`;

const ContentArea = styled.div`
  margin: 50px auto auto 50px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h1`
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
`;

export const UnauthorizedPage = () => {
  const { logout } = useAccount();
  const scrolledHeight = 50;
  const hasScrolled = useHasScrolled(scrolledHeight);

  return (
    <div>
      <HeaderWithoutNav scrolled={hasScrolled} />
      <ContentArea>
        <Header>Unauthorized</Header>
        <Text>{errorMessage}</Text>
        <div>
          <BoxButton onClick={logout} title="Log out">Log out</BoxButton>
        </div>
      </ContentArea>
    </div>
  );
};
