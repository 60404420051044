import React from 'react';
// import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

interface ChevronBaseProps {
  top?: boolean;
  bottom?: boolean;
  left?: boolean;
  right?: boolean;
  big?: boolean;
  thin?: boolean;
}
const StyledChevron = styled.div<ChevronBaseProps>`
  position: relative;
  height: 18px;
  width: 18px;

  ${props => props.big && css`
    height: 26px;
    width: 26px;
  `};

  &::before {
    content: '';
    position: absolute;
    display: block;
    height: ${props => props.big ? '1em' : '0.6em'} ;
    width: ${props => props.big ? '1em' : '0.6em'} ;
    border-width: ${props => props.thin ? '0.1em 0.1em 0 0' : '0.15em 0.15em 0 0'};
    border-style: solid;
    box-sizing: border-box;
    transition: all 0.3s ease;

    ${props => props.top && css`
      top: ${props.big ? '0.6em' : '0.35em'};
      left: ${props.big ? '0.4em' : '0.22em'};
      transform: rotate(-45deg);
    `}
    ${props => props.bottom && css`
      top: ${props.big ? '0.05em' : '0.05em'};
      left: ${props.big ? '0.4em' : '0.22em'};
      transform: rotate(135deg);
    `}
    ${props => props.left && css`
      top: ${props.big ? '0.33em' : '0.2em'};
      left: ${props.big ? '0.5em' : '0.35em'};
      transform: rotate(-135deg);
    `}
    ${props => props.right && css`
      top: ${props.big ? '0.33em' : '0.2em'};
      left: ${props.big ? '0em' : '0.1em'};
      transform: rotate(45deg);
    `}
  };
`;

export interface ChevronProps extends ChevronBaseProps {
  className?: string;
}
export const Chevron = ({ top, bottom, left, right, big, thin, className }: ChevronProps) => (
  <StyledChevron top={top} bottom={bottom} left={left} right={right} big={big} thin={thin} className={className} />
);

interface ChevronToggleProps {
  open: any;
  big?: boolean;
  thin?: boolean;
}
export const ChevronToggle = ({ open, big, thin }: ChevronToggleProps) => (
  <Chevron top={open} bottom={!open} big={big} thin={thin} />
);
