import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Add } from '@instech/icons';
import { useCompanyByExternalId } from '@/services/companiesService';
import { PortalSelection } from '@/enrollment/stepComponents/AddPortalStep/PortalSelection';
import { BoxButton } from '@/components/shared/BoxButton/BoxButton';
import { Loader } from '../../shared/Loader';
import { Users } from './Users';
import { CompanyInfo } from '../../shared/CompanyInfo/CompanyInfo';

const PageContent = styled.div`
  margin: 0 auto;
  padding: 64px 12px;
  height: 100%;
  max-width: 1372px; // 1200px + 12px + 160px
`;

const PageTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue50};
`;

const CompanyTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
  margin-top: 6px;
  margin-bottom: 30px;
`;

const Columns = styled.div`
  display: flex;
`;

const InfoColumn = styled.div`
  width: 100%;
`;

const ButtonColumn = styled.div`
  width: 160px;
  margin-left: 12px;
  flex-shrink: 0;
`;

const StyledButton = styled(BoxButton)`
  width: 100%;
  margin-bottom: 8px;
  > div {
    padding: 12px 8px;
    justify-content: flex-start;
  }
`;

export const CompanyPage = () => {
  const { companyId }: { companyId: string } = useParams();
  const { data: company } = useCompanyByExternalId(companyId);
  const history = useHistory();

  if (!company) return <Loader />;

  const addUserClick = () => history.push(`/add-company/add-users/${companyId}`);

  return (
    <PageContent>
      <PageTitle>Company overview</PageTitle>
      <CompanyTitle>{company.name}</CompanyTitle>
      <Columns>
        <InfoColumn>
          <CompanyInfo company={company} />
          <PortalSelection companyId={company.id} title="Portals" />
          <Users companyId={companyId} />
        </InfoColumn>
        <ButtonColumn>
          <StyledButton startIcon={<Add />} onClick={addUserClick}>
            Add User
          </StyledButton>
        </ButtonColumn>
      </Columns>
    </PageContent>
  );
};
