export const formatBytes = (bytes?: number, decimals = 2) => {
  if (!bytes || bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

export const maxFileSize = 25 * 1024 * 1024;

export const maxFileSizeString = formatBytes(maxFileSize, 0);

export const isValidFileExtension = (fileName: string, validFileExtensions: string[]) => {
  const extension = fileName?.split('.').pop()?.toLowerCase();
  return validFileExtensions.includes(`.${extension}`);
};
