import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: ${props => props.width ? props.width : '40px'};
  height: ${props => props.height ? props.height : '40px'};
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    flex-shrink: 0;
    width: ${props => props.small ? '18px' : '25px'};
  }
  &:disabled {
    cursor: default;
  } 
`;

export const IconButton = ({ icon, smallIcon, width, height, onClick, disabled = false, ariaLabel, className }) => (
  <StyledButton
    small={smallIcon}
    width={width}
    height={height}
    onClick={onClick}
    type="button"
    disabled={disabled}
    aria-label={ariaLabel}
    className={className}
  >
    {icon}
  </StyledButton>
);

IconButton.propTypes = {
  icon: PropTypes.node,
  smallIcon: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  className: PropTypes.string
};
