import { BusinessAccount } from '@/types';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { CellContent, CellTitle } from './Components';

const Wrapper = styled.div`
  display: flex;
`;

const TitleBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: ${props => props.theme.blue};
  font-weight: bold;
  font-size: 14px;
`;

const ContentBox = styled.div`
  flex: 3;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 24px;
`;

interface BusinessAccountProps { businessAccount: BusinessAccount }
const BusinessAccountCell: FunctionComponent<BusinessAccountProps> = ({ businessAccount }) => (
  <div>
    <CellTitle>{businessAccount.id}</CellTitle>
    <CellContent>{businessAccount.name}</CellContent>
  </div>
);

interface BusinessAccountsProps { businessAccounts: BusinessAccount[] }
export const BusinessAccounts: FunctionComponent<BusinessAccountsProps> = ({ businessAccounts }) => (
  <Wrapper>
    <TitleBox>
      <Title>Bus.Acc</Title>
    </TitleBox>
    <ContentBox>
      <Grid>
        {businessAccounts.map(businessAccount => <BusinessAccountCell key={businessAccount.id} businessAccount={businessAccount} />)}
      </Grid>
    </ContentBox>
  </Wrapper>
);
