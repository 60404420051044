import React from 'react';
import { useModalContext } from '@/components/modal/ModalContext';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router';
import { BoxButtonSlim } from '@/components/shared/BoxButton';

const Text = styled.p<{ bold?: boolean }>`
  font-size: 16px;
  ${props => props.bold && css`
    font-weight: bold;  
  `};
  text-align: center;
  margin-top: 0px;
  color: ${props => props.theme.marineBlue};
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 80px;
  justify-content: center;
`;

export interface ConfirmationModalArgs {
  firstStringContent: string,
  secondStringContent: string,
  confirmButtonText: string,
  rejectButtonText: string,
  confirmCallback?: () => void
  rejectCallBack?: () => any
  returnToCallBack?: () => void;
}

const ConfirmationModal = ({
  confirmButtonText,
  rejectButtonText,
  firstStringContent,
  secondStringContent,
  confirmCallback,
  rejectCallBack,
  returnToCallBack,
} : ConfirmationModalArgs) => {
  const history = useHistory();
  const { close: closeModal } = useModalContext();

  const rejectModal = async () => {
    if (rejectCallBack) rejectCallBack();

    closeModal();
  };

  const confirmModal = async () => {
    if (confirmCallback) confirmCallback();

    closeModal();
    if (returnToCallBack) {
      returnToCallBack();
    } else {
      history.push('/announcements');
    }
  };

  return (
    <>
      <Text bold>{firstStringContent}</Text>
      <Text>{secondStringContent}</Text>
      <StyledButtonGroup>
        <BoxButtonSlim width="164px" height="40px" inverted onClick={rejectModal}>{rejectButtonText}</BoxButtonSlim>
        <BoxButtonSlim width="164px" height="40px" onClick={confirmModal}>{confirmButtonText}</BoxButtonSlim>
      </StyledButtonGroup>
    </>
  );
};

export const CancelUpdateConfirmationModalData: ConfirmationModalArgs = {
  firstStringContent: 'Do you wish to discard changes?',
  secondStringContent: 'Your changes will not be saved',
  confirmButtonText: 'Discard changes',
  rejectButtonText: 'Continue editing'
};

export const PushChangesConfirmationModalData: ConfirmationModalArgs = {
  firstStringContent: 'Do you wish to push your changes to this Update?',
  secondStringContent: `The published Update will be revised to include your changes, 
    but will not change the time of the original post, or notify users of changes.`,
  confirmButtonText: 'Push changes',
  rejectButtonText: 'Continue editing'
};

export const openConfirmationModal = (options: any, args: ConfirmationModalArgs) => ({
  component: ConfirmationModal,
  options: { size: 'small', ...options },
  args
});
