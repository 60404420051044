import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Chevron } from './Chevron';
import { IconButton } from './LinkButton/IconButton';

const ButtonWrapper = styled.div<{ lineLeft?: boolean, center?: boolean; }>`
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 10px;

  ${props => props.lineLeft && css`
    border-left: 1px solid;
    border-left-color: ${props.theme.lightGray};
  `};
  ${props => props.center && css`
    justify-content: center;
  `};
`;

interface CellButtonProps {
  center?: boolean;
  lineLeft?: boolean;
  smallIcon?: boolean;
  icon: any;
  onClick?: () => any;
  disabled?: boolean;
  ariaLabel?: string;
}
export const CellButton: FunctionComponent<CellButtonProps> = (
  { center, lineLeft, smallIcon, icon, onClick, disabled, ariaLabel }
) => (
  <ButtonWrapper center={center} lineLeft={lineLeft}>
    <IconButton
      icon={icon}
      smallIcon={smallIcon}
      onClick={onClick}
      width="40px"
      height="40px"
      disabled={disabled}
      ariaLabel={ariaLabel}
    />
  </ButtonWrapper>
);

export const CellButtonChevron = styled(Chevron) <{ disabled?: boolean }>`
  color: ${props => props.disabled ? props.theme.mediumGray : props.theme.marineBlue};
  margin-top: 1px;
`;

export const TableCell = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 20px;
  min-height: 32px; // +8px padding, 40px min height
  color: ${props => props.theme.marineBlue};
  overflow-wrap: anywhere;
`;

export const TableHeader = styled(TableCell)`
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid ${props => props.theme.marineBlue};
`;

interface TableRowProps {
  even: boolean;
  selected?: boolean;
  selectable?: boolean;
}
export const TableRow = styled.div<TableRowProps>`
  display: contents;

  ${props => props.selectable && css`
    cursor: pointer;
  `}

  ${TableCell}, ${ButtonWrapper} {
    ${props => !props.even && css`
      background-color: ${props.theme.ultraLightGray2};
    `}
    ${props => props.selected && css`
      background-color: ${props.theme.whiteBlue};
    `}
  }

  :hover {
    ${TableCell}, ${ButtonWrapper} {
      background-color: ${props => props.theme.marineBlue20};
    }
  }
`;

interface TableProps {
  columns?: number;
  gridTemplateColumns?: string;
}
export const Table = styled.div<TableProps>`
  display: grid;
  ${props => props.columns && css`grid-template-columns: repeat(${props.columns}, auto)`};
  ${props => props.gridTemplateColumns && css`grid-template-columns: ${props.gridTemplateColumns}`};
  width: 100%;
  background-color: ${props => props.theme.white};
`;
