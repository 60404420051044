import React, {
  useRef, useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useField } from 'formik';
import { TruncatingTextMiddle } from '@/components/shared/Text';
import { formatBytes } from '@/utils/file';
import { ErrorMessage } from '../../core/Components';
import { Dropzone } from './core/Dropzone';

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PreviewText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  max-width: 300px;
  color: ${props => props.error ? props.theme.red : props.theme.marineBlue};
`;

const FileName = styled.span`
  font-weight: bold;
  margin-bottom: 6px;
`;

const Placeholder = styled.span`
  display: block;
  text-align: center;
  font-style: italic;
`;

const Image = styled.img`
  width: auto;
  height: 100px;
  border: 1px solid ${props => props.error ? props.theme.red : props.theme.border.gray};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImageUploader = ({ name, showPreview = true, error }) => {
  const [, meta, helpers] = useField(name);
  const [isHovered, setHover] = useState(false);
  const imgEl = useRef(null);

  useEffect(() => {
    if (imgEl.current && imgEl.current.src !== '' && meta.value == null) {
      imgEl.current.src = '';
    }
  }, [imgEl, meta.value]);

  const handleFile = file => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      if (imgEl.current) imgEl.current.src = URL.createObjectURL(file);
    };
    reader.readAsDataURL(file);
    helpers.setValue(file);
  };

  const startHover = evt => {
    evt.preventDefault();
    setHover(true);
  };

  const endHover = evt => {
    evt.preventDefault();
    setHover(false);
    if (evt.type === 'drop') {
      handleFile(evt.dataTransfer.files[0]);
    }
  };

  const clickUpload = evt => {
    handleFile(evt.currentTarget.files[0]);
  };

  return (
    <Wrapper>
      <Dropzone
        htmlFor={`dropzone-${name}`}
        isHovered={isHovered}
        onDragEnter={startHover}
        onDragOver={startHover}
        onDragEnd={endHover}
        onDragLeave={endHover}
        onDrop={endHover}
        error={meta.error || error}
      >
        {(!meta.value || !showPreview) && <Placeholder>Click or drop image to upload</Placeholder>}
        {meta.value && showPreview && (
          <PreviewContainer>
            <Image src="" alt="" ref={imgEl} error={meta.error} />
            <PreviewText error={meta.error}>
              <FileName><TruncatingTextMiddle text={meta.value.name} maxLen={24} /></FileName>
              <span>{formatBytes(meta.value.size)}</span>
            </PreviewText>
          </PreviewContainer>
        )}
        <input id={`dropzone-${name}`} type="file" onChange={clickUpload} accept="image/jpg, image/jpeg, image/gif, image/png" />
      </Dropzone>
      <ErrorMessage>
        {meta.error ? meta.error : ''}
      </ErrorMessage>
    </Wrapper>
  );
};
ImageUploader.propTypes = {
  name: PropTypes.string.isRequired,
  showPreview: PropTypes.bool,
  error: PropTypes.string
};
