import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useCompanyUsers } from '@/services/usersService';
import { User } from '@/types';
import { InfoCircle } from '@instech/icons';
import { Loader } from '../../shared/Loader';
import {
  Table, TableCell, TableHeader, TableRow
} from '../../shared/Table';

const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.marineBlue};
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-left: 3px;
  & svg {
    height: 16px;
    width: 16px;
    margin-left: 7px;
  };
`;

const HeaderRow = () => (
  <>
    <TableHeader>Name</TableHeader>
    <TableHeader>Title</TableHeader>
    <TableHeader>Email</TableHeader>
    <TableHeader>Phone</TableHeader>
    <TableHeader>Active / Passive</TableHeader>
  </>
);

interface RowProps { user: User; even: boolean; }
export const Row: FunctionComponent<RowProps> = ({ user, even }) => (
  <TableRow even={even}>
    <TableCell>{user.displayName}</TableCell>
    <TableCell>{user.title}</TableCell>
    <TableCell>{user.email}</TableCell>
    <TableCell>{user.phoneNumber}</TableCell>
    <TableCell>{user.enabled ? 'Active' : 'Passive'}</TableCell>
  </TableRow>
);

interface UsersProps { companyId: string; }
export const Users: FunctionComponent<UsersProps> = ({ companyId }) => {
  const { data: users } = useCompanyUsers(companyId);

  if (!users) return <Loader />;

  return (
    <>
      <Title>
        Users
        <InfoCircle />
      </Title>
      <Table columns={5}>
        <HeaderRow />
        {users.map((user, i) => <Row key={user.id} user={user} even={i % 2 === 0} />)}
      </Table>
    </>
  );
};
