import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-style: italic;
  /* white-space: nowrap; */
`;

const SearchTerm = styled.span`
  font-weight: bold;
`;
interface Props {
  searchTerm?: string;
}

export const NoResults = ({ searchTerm }: Props): ReactElement => (
  <Wrapper>
    No companies match the search term
    {searchTerm && (
      <SearchTerm>
        &quot;
        {searchTerm}
        &quot;
      </SearchTerm>
    )}
  </Wrapper>
);
