import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ImageBorder } from './ImageBorder';

export const ImageErrorContent = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${props => props.theme.lightGray};
  box-sizing: border-box;
`;
interface ImageCardLoadErrorProps {
  height?: string;
  width?: string;
  noBorder?: boolean;
  isSelected?: boolean;
  onClick?: () => any;
}
export const ImageCardLoadError: FunctionComponent<ImageCardLoadErrorProps> = (props: ImageCardLoadErrorProps) => (
  <ImageBorder {...props}>
    <ImageErrorContent>Could not load image</ImageErrorContent>
  </ImageBorder>
);
