import styled from 'styled-components';

export const Dropzone = styled.label<{error?: string | boolean}>`
  display: block;
  padding: 30px;
  border: 2px dashed;
  border-color: ${props => props.error ? props.theme.red : props.theme.border.gray};
  border-radius: 6px;
  cursor: pointer;
  background-color: ${props => props.theme.white};

  &:focus, &:hover {
    border-color: ${props => props.error ? props.theme.red : props.theme.marineBlue};
  }

  & input {
    display: none;
  }
`;
