import React, { useState } from 'react';
import styled from 'styled-components';
import { SaveCloseButtons } from '@/components/shared/BoxButton/variants/SaveCloseButtons';
import { UserInRole } from '@/types';
import { Form, Formik } from 'formik';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { setUserRolesAsync } from '@/services/usersService';

const PopupAnchor = styled.div`
  position: relative;
  height: 0;
`;

const Popup = styled.div`
  width: 300px;
  padding: 12px;
  position: absolute;
  right: 0;
  background: white;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
`;

const CheckboxGroup = styled.div`
  padding: 8px;
  background-color: ${props => props.theme.lightBlue};
  margin-bottom: 12px;
`;

interface Props {
  onClose: () => void;
  onSave: () => void;
  roles: UserInRole[];
  canEdit: boolean;
  userId: number;
  applicationId: number;
}
export const RoleSelectPopup = ({ onClose, onSave, roles, userId, applicationId, canEdit }: Props) => {
  const [error, setError] = useState<string | undefined>(undefined);
  if (error) {
    throw new Error(error);
  }
  return (
    <PopupAnchor>
      <Popup>
        <Formik
          initialValues={{ roles }}
          onSubmit={async (values, { setSubmitting }) => {
            const submitValues = values.roles
              .filter(role => role.selected)
              .map(role => role.id);
            try {
              await setUserRolesAsync(userId, applicationId, submitValues);
              setSubmitting(false);
              onSave();
            } catch (err) {
              setError(err);
            }
          }}
        >
          {({ values, handleSubmit, isSubmitting, dirty }) => (
            <Form>
              <CheckboxGroup>
                {values.roles.map((role, index) => (
                  <div key={role.id}>
                    <Checkbox name={`roles.${index}.selected`} rightLabel={role.name} noErrors disabled={!canEdit} />
                  </div>
                ))}
              </CheckboxGroup>
              <SaveCloseButtons
                fullWidth
                closeText="Cancel"
                onClose={onClose}
                onSave={!isSubmitting ? handleSubmit : undefined}
                saveText="Done"
                submitting={isSubmitting}
                disableSave={!dirty}
              />
            </Form>
          )}

        </Formik>
      </Popup>
    </PopupAnchor>

  );
};
