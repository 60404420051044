/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAccount } from 'its-js-authentication';
import { useLocation, RouteProps } from 'react-router';
import { Location } from 'history';
import { useRoles } from '@/services/rolesServices';

const redirectState = (pathname: string, location: Location) => ({
  pathname,
  state: { from: location }
});

const LoginRedirect = () => {
  const location = useLocation();
  return (
    <Redirect to={redirectState('/login', location)} />
  );
};

const AuthenticationHandler: FunctionComponent<any> = ({ children }) => {
  const { isAuthenticated } = useAccount();
  const { data: userRoles } = useRoles();

  if (isAuthenticated !== true) {
    return <LoginRedirect />;
  }

  if (userRoles?.length === 0) {
    return <Redirect to="/unauthorized" />;
  }

  return children;
};

export const ProtectedRoute: FunctionComponent<RouteProps> = ({ children, ...rest }) => (
  <Route {...rest}>
    <AuthenticationHandler>
      {children}
    </AuthenticationHandler>
  </Route>
);
