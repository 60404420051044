import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Loader } from '@/components/shared/Loader';
import { ExternalCompany } from '@/types';
import {
  Table, TableHeader, TableCell, TableRow
} from '@/components/shared/Table';
import { Heading2 } from '@/components/shared/Headings/Heading2';
import { NoResults } from './NoResults';

const HeaderRow = () => (
  <>
    <TableHeader />
    <TableHeader>Company Name</TableHeader>
    <TableHeader>CRM Number</TableHeader>
    <TableHeader>Country</TableHeader>
  </>
);

const StyledTable = styled(Table)`
  box-shadow: 0 1px 6px 0 rgba(173,173,173,0.5);
`;

interface RowProps {
  company: ExternalCompany;
  even: boolean;
  selected: boolean;
  onClick: () => void;
}
const Row: FunctionComponent<RowProps> = ({ company, even, selected, onClick }) => (
  <TableRow even={even} selected={selected} onClick={onClick} selectable>
    <TableCell>{selected ? <input type="checkbox" checked readOnly /> : ''}</TableCell>
    <TableCell>{company.name}</TableCell>
    <TableCell>{company.companyNumber}</TableCell>
    <TableCell>{company.country}</TableCell>
  </TableRow>
);

interface CompanyRowsProps {
  companies: ExternalCompany[];
  selectedCompanyId?: string;
  setSelectedCompanyId: (newId?: string) => void;
}
const CompanyRows = ({ companies, selectedCompanyId, setSelectedCompanyId }: CompanyRowsProps) => (
  <>
    {companies.map((company, i) => (
      <Row
        key={company.id}
        company={company}
        even={i % 2 === 0}
        selected={selectedCompanyId === company.id}
        onClick={() => setSelectedCompanyId(company.id)}
      />
    ))}
  </>
);

interface Props {
  companies?: ExternalCompany[];
  selectedCompanyId?: string;
  setSelectedCompanyId: (newId?: string) => void;
  searchTerm?: string;
  isLoading: boolean;
}

export const SearchResults = ({ companies, selectedCompanyId, setSelectedCompanyId, searchTerm, isLoading }: Props) => {
  if (isLoading) {
    return <Loader />;
  }
  if (!companies) {
    return null;
  }

  if (companies.length === 0) {
    return <NoResults searchTerm={searchTerm} />;
  }

  return (
    <div>
      <Heading2>Search results</Heading2>
      <StyledTable gridTemplateColumns="32px 1fr 1fr 0.5fr">
        <HeaderRow />
        <CompanyRows
          companies={companies}
          selectedCompanyId={selectedCompanyId}
          setSelectedCompanyId={setSelectedCompanyId} />
      </StyledTable>
    </div>
  );
};
