import moment from 'moment';

// Convert moment to string: "YYYY-MM-DDTHH:mm:ss.offset"
export const getDateISOString = (date: moment.Moment) => date === null ? null : date.utc().startOf('date').toISOString();

export const parseISODateString = (dateString: string) => moment.utc(dateString, moment.ISO_8601);

export const normalizeDateString = (date: string) => moment.utc(date).toISOString();

export const getCurrentTimezoneOrDefault = () => Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'UTC';

export function constructDateTime(date?: string | null, timeOfDay?: string | null, inUTC: boolean = false) {
  if (!date) return undefined;
  // Force Moment to expect ISO-8601 to keep it from trying browser Date(), as Chromium v8
  // will otherwise treat certain unsupported date formats as though they are valid.
  const datePart = moment.utc(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
  let suffix = '';
  if (timeOfDay) {
    const containsSeconds = timeOfDay.split(':').length > 2;
    suffix = containsSeconds ? '.00000' : ':00.00000';
  }
  const timePart = timeOfDay ? `${timeOfDay}${suffix}` : '00:00:00.00000';
  return `${datePart}T${timePart}${inUTC ? 'Z' : ''}`;
}

/**
 * Formats a timeOfDay string to the format 'hh:mm'
 */
export function formatTimeOfDay(timeOfDay: string) {
  const timeComponents = timeOfDay.split(':');

  if (timeComponents.length < 2) {
    throw new Error('Invalid input. Must be of format hh:mm:ss');
  }
  const hours = timeComponents[0];
  const minutes = timeComponents[1];

  return `${hours}:${minutes}`;
}
