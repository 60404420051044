import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Spacing = styled.div<{ padding: string}>`
  padding: ${props => props.padding};
`;

const DividerBar = styled.div`
  width: 100%;
  border-top: 1px solid ${props => props.theme.background.primary};
`;

interface Props {
  padding?: string;
}
export const Divider: FunctionComponent<Props> = ({ padding = '20px 0px' }) => (
  <Spacing padding={padding}>
    <DividerBar />
  </Spacing>
);
