import { AddCompanyProgress } from '@/enrollment/core/AddCompanyProgress';
import { NextButton } from '@/enrollment/core/NextButton';
import { TopSection } from '@/enrollment/core/TopSection';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useCompanyByExternalId } from '@/services/companiesService';
import { Loader } from '@/components/shared/Loader';
import { CompanyInfo } from '@/components/shared/CompanyInfo/CompanyInfo';
import { CompanyTitle } from '@/components/shared/CompanyInfo/Components';
import { PortalSelection } from './PortalSelection';

const NextPageButton = () => {
  const { companyId } = useParams() as any;
  const history = useHistory();
  const route = `/add-company/add-users/${companyId}`;
  return (
    <NextButton submit={() => history.push(route)} />
  );
};

const CompanyLoader = () => {
  const { companyId } = useParams() as any;
  const { data: company, error } = useCompanyByExternalId(companyId);

  if (error) {
    throw new Error(error);
  }

  if (!company) {
    return <Loader />;
  }

  return (
    <>
      <CompanyTitle>{company.name}</CompanyTitle>
      <CompanyInfo company={company} />
      <PortalSelection companyId={company.id} title="Add Portals" />
    </>
  );
};

export const AddPortalStep = () => (
  <div>
    <TopSection>
      <AddCompanyProgress currentStep={1} />
      <NextPageButton />
    </TopSection>
    <CompanyLoader />
  </div>
);
