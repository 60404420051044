import React from 'react';
import styled from 'styled-components';
import { ArrowBack, ArrowForward } from '@instech/icons';
import { BoxButtonSlim } from '@/components/shared/BoxButton';

export const ProgressButtons = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 130px;
  margin-left: 24px;
  & > button {
    margin-bottom: 8px;
  }
`;

interface ButtonProps {
  width?: string;
  disabled?: boolean;
  onClick: () => any;
  inverted?: boolean;
}

export const NextButton = (props: ButtonProps) => (
  <BoxButtonSlim endIcon={<ArrowForward />} {...props}>
    Next
  </BoxButtonSlim>
);

export const PreviousButton = (props: ButtonProps) => (
  <BoxButtonSlim inverted startIcon={<ArrowBack />} {...props}>
    Previous
  </BoxButtonSlim>
);

export const PublishButton = (props: ButtonProps) => (
  <BoxButtonSlim {...props}>
    Publish Now
  </BoxButtonSlim>
);

export const SchedulePublishButton = (props: ButtonProps) => (
  <BoxButtonSlim inverted {...props}>
    Schedule Publish
  </BoxButtonSlim>
);

export const PushChangesButton = (props: ButtonProps) => (
  <BoxButtonSlim {...props}>
    Push Changes
  </BoxButtonSlim>
);

export const CancelButton = (props: ButtonProps) => (
  <BoxButtonSlim inverted {...props}>
    Cancel
  </BoxButtonSlim>
);

export const SaveDraftButton = (props: ButtonProps) => (
  <BoxButtonSlim inverted {...props}>
    Save Draft
  </BoxButtonSlim>
);

export const UploadImageButton = (props: ButtonProps) => (
  <BoxButtonSlim inverted {...props}>
    Upload thumbnail
  </BoxButtonSlim>
);
