import { FormikProps, getIn } from 'formik';
import {
  Article, Option, Announcement
} from '@/types';

// Last optional value is a workaround to allow data submit and
// then conditionally direct user to another part of the wizard
export interface FormValues {
  categoryOption: Option;
  keywordOption: Option;
  title: string;
  shortText: string;
  articles: Article[];
  cardImageId?: string;
  jumpFunc?: () => void;
}

export const newArticle: Article = {
  text: '',
  images: []
};

export const getInitialValues = (announcement: Announcement) => ({
  categoryOption: announcement.categoryOption ?? '',
  keywordOption: announcement.keywordOption ?? '',
  title: announcement.title ?? '',
  shortText: announcement.shortText ?? '',
  articles: announcement.articles.length ? announcement.articles : [newArticle],
  cardImageId: announcement.cardImageId ?? ''
});

// Helper function to look up the error message of a
// nested field inside a FieldArray and show it when touched
export const getFieldArrayError = (formik: FormikProps<FormValues>, name: string) => {
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);
  return touched && error ? error : null;
};
