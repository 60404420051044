import React, { FunctionComponent } from 'react';
import {
  useImagePreviewById, Crop, ImagePreview
} from '@/services/imageServices';
import { LoaderArea } from './core/LoaderArea';
import { Image } from './core/Image';
import { ImageCardLoadError } from './core/ImageCardLoadError';

interface Props {
  imageId: string;
  alt?: string;
  width?: string;
  height?: string;
  darkBorder?: boolean;
  noBorder?: boolean;
  isSelected?: boolean;
  onClick?: (image: ImagePreview) => void;
  disableClick?: any;
  crop: Crop;
}
export const ImageView: FunctionComponent<Props> = (
  { imageId, crop, alt, width, height, darkBorder, noBorder, isSelected, onClick, disableClick }
) => {
  const { data: image, error, isValidating } = useImagePreviewById(imageId, crop);

  if (error && !isValidating) {
    return (
      <ImageCardLoadError
        width={width}
        height={height}
        noBorder={noBorder}
      />
    );
  }

  if (!image) {
    return <LoaderArea width={width} height={height} />;
  }
  if (image?.cropStatus === 'Error') {
    return (
      <ImageCardLoadError
        onClick={onClick ? () => onClick(image) : undefined}
        isSelected={isSelected}
        width={width}
        height={height}
        noBorder={noBorder}
      />
    );
  }

  return (
    <Image
      url={image.url}
      alt={alt || image.title}
      width={width}
      height={height}
      darkBorder={darkBorder}
      noBorder={noBorder}
      isSelected={isSelected}
      onClick={onClick ? () => onClick(image) : null}
      disableClick={disableClick}
    />
  );
};
