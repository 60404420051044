/* eslint-disable no-plusplus */

type genericObj = { [key: string]: any; }

/**
 * Perform a Deep Equal check, looking through and comparing individual props
 * to see whether two objects are identical. Takes two generic objects and
 * returns a boolean for object equality.
 */
export const objectsDeepEqual = (first: genericObj, second: genericObj) => {
  const firstProps = Object.getOwnPropertyNames(first);
  const secondProps = Object.getOwnPropertyNames(second);
  // Inequal number of props
  if (firstProps.length !== secondProps.length) {
    return false;
  }
  for (let i = 0; i < firstProps.length; i++) {
    const prop: any = firstProps[i];
    // Either object has a prop the other does not have
    if (firstProps[prop] !== secondProps[prop]) {
      return false;
    }
    // Contents of props do not match, could maybe do a deeper equal here
    if (JSON.stringify(first[prop]) !== JSON.stringify(second[prop])) {
      return false;
    }
  }
  return true;
};
