import React, {
  FunctionComponent as FC, useEffect, useRef, useState
} from 'react';
import styled from 'styled-components';
import { Search as SearchIcon, Close as CloseIcon } from '@instech/icons';
import { IconButton } from '@/components/shared/BoxButton';
import * as constants from './constants';
import { ClearButton, SearchInput } from './Components';

const SearchButton = styled(IconButton)`
  width: 80px;
  height: 52px;
  border-radius: 0 5px 5px 0;
` as any;

const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
`;

interface Props {
  onReset: () => void;
  onSearch: (text: string) => any;
  hintText?: string;
  hasLeftActions?: boolean;
}
export const Search: FC<Props> = ({ onReset, onSearch, hintText = 'Search', hasLeftActions = false }) => {
  const [searchText, setSearchText] = useState('');

  const inputEl = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.focus();
    }
  }, []);

  const searchTextChanged = (newSearchText: string) => {
    setSearchText(newSearchText);
  };

  const search = (text: string) => {
    onSearch(text);
  };

  const reset = () => {
    if (searchText) {
      onReset();
      setSearchText('');
    }
  };

  const keyUpHandler = (event: React.KeyboardEvent) => {
    if (event.key === constants.ENTER_KEY) {
      search(searchText);
    } else if (event.key === constants.ESCAPE_KEY) {
      reset();
    }
  };

  return (
    <>
      <InputWrapper>
        <ClearButton
          type="button"
          hidden={searchText === ''}
          roundLeft={hasLeftActions}
          onClick={reset}
          disabled={searchText === ''}
        >
          <CloseIcon />
        </ClearButton>
        <SearchInput
          aria-label="Search"
          ref={inputEl}
          type="text"
          placeholder={hintText}
          onChange={e => searchTextChanged(e.target.value)}
          onKeyUp={keyUpHandler}
          value={searchText}
        />
      </InputWrapper>

      <SearchButton
        icon={<SearchIcon />}
        onClick={() => search(searchText)}
        ariaLabel="Submit"
      />
    </>
  );
};
