import React, {
  useContext, useEffect, useMemo, useReducer
} from 'react';
import {
  useHistory, useParams, useRouteMatch
} from 'react-router';
import { Dictionary } from '@/types';
import { routes } from '../utils';

interface ProgressContextType {
  next: () => void;
  previous: () => void;
  jump: (location: ProgressKey) => void;
  state: ProgressKey;
}

const nextStateGraph = {
  recipients: {
    next: 'content',
    previous: null,
    jump: null
  },
  content: {
    next: 'preview',
    previous: 'recipients',
    jump: null
  },
  preview: {
    next: null,
    previous: 'content',
    jump: null
  },
};

export type ProgressKey = 'recipients' | 'content' | 'preview' | null;
type ActionType = 'next' | 'previous' | 'jump';
const actions: Dictionary<ActionType> = {
  next: 'next',
  previous: 'previous',
  jump: 'jump'
};

const reducer = (state: ProgressKey, { action, location }: { action: ActionType, location?: ProgressKey }): ProgressKey => {
  if (action === actions.jump && location) return location;

  const nextState = state && nextStateGraph[state][action] as ProgressKey | null;
  return nextState || state;
};

const getStepFromPath = (path: string) => {
  const validPath = path.endsWith(':announcementId');
  if (!validPath) return null;

  const matchesForStep = path.match(/^\/announcements\/new-announcement\/(.*)\/:announcementId/);
  const urlContainsStep = matchesForStep && matchesForStep.length === 2;

  if (urlContainsStep) {
    const step = matchesForStep![1];
    return step as ProgressKey;
  }

  return null;
};

const getStepFromUrl = (url: string) => {
  const matchesForStep = url.match(/^\/announcements\/new-announcement\/(.*)\/.*/);
  const urlContainsStep = matchesForStep && matchesForStep.length === 2;

  if (urlContainsStep) {
    const step = matchesForStep![1];
    return step as ProgressKey;
  }

  return null;
};

const initialValues = {
  next: () => {},
  previous: () => {},
  jump: (location: ProgressKey) => {},
  state: null
};

const ProgressContext = React.createContext<ProgressContextType>(initialValues);

export const ProgressProvider = ({ children, initialPath }: { children?: React.ReactNode, initialPath: string }) => {
  const initialStep = getStepFromPath(initialPath);
  const [state, dispatch] = useReducer(reducer, initialStep);
  const history = useHistory();
  const { url: currentUrl } = useRouteMatch();
  const { announcementId }: { announcementId: string } = useParams();

  // Navigate to new URL iff the state changes
  useEffect(() => {
    if (!(announcementId && state)) return;

    const newPath = routes[state].replace(':announcementId', announcementId);
    if (newPath !== currentUrl) {
      const currentPathname = history.location.pathname;
      history.push(newPath);

      // If the push was canceled by PromptOnUnsavedChanges, set the state back to the current location
      if (currentPathname === history.location.pathname) {
        const location = getStepFromUrl(history.location.pathname);
        dispatch({ action: actions.jump, location });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const value = useMemo(() => {
    const next = () => dispatch({ action: actions.next });
    const previous = () => dispatch({ action: actions.previous });
    const jump = (location: ProgressKey) => dispatch({ action: actions.jump, location });

    return ({ next, previous, jump, state });
  }, [state]);

  return (
    <ProgressContext.Provider
      value={value}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export const useProgress = () => useContext(ProgressContext);
