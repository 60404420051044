const rootUrl = '/announcements/new-announcement';
const getRoute = (route: string) => `${rootUrl}${route}`;

export const routes = {
  newAnnouncement: getRoute('/recipients'),
  recipients: getRoute('/recipients/:announcementId'),
  content: getRoute('/content/:announcementId'),
  preview: getRoute('/preview/:announcementId')
};

export const getReturnToCallBack = (setShouldRedirect: (val: boolean) => void, resetForm: () => void) => async () => {
  await resetForm();
  setShouldRedirect(true);
};
