import React from 'react';
import styled from 'styled-components';
import { Divider } from '@/components/shared/Divider';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { BoxButton } from '@/components/shared/BoxButton/BoxButton';

const Heading = styled.div`
  font-weight: bold;
`;

const Text = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

interface Props {
  portalName: string;
  confirmHandler: () => any;
  cancelHandler: () => any;
}

const ConfirmRemovalModal = (props: Props) => (
  <div>
    <Heading>{`Are you sure you want to remove access to the portal "${props.portalName}"?`}</Heading>
    <Text>This will remove access to the portal for every company user.</Text>
    <Divider />
    <ButtonGroup alignRight>
      <BoxButton inverted onClick={props.cancelHandler}>
        Cancel
      </BoxButton>
      <BoxButton onClick={props.confirmHandler}>
        REMOVE ACCESS TO PORTAL
      </BoxButton>
    </ButtonGroup>
  </div>
);

export const openConfirmRemovalModal = ({ portalName, confirmHandler, cancelHandler }: Props) => ({
  component: ConfirmRemovalModal,
  options: {
    title: 'Remove Access to Portal',
    size: 'small'
  },
  args: { portalName, confirmHandler, cancelHandler }
});
