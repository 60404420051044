import styled from 'styled-components';

export const CellContent = styled.div`
  color: ${props => props.theme.blue};
  font-size: 14px;
`;

export const CellTitle = styled(CellContent)`
  font-weight: bold;
`;

export const CompanyTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
  margin-top: 6px;
  margin-bottom: 30px;
`;
