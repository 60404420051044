import React, {
  createContext, useContext, FunctionComponent, useMemo
} from 'react';

export interface ContextData {
  open: (component: unknown) => void;
  close: () => void;
}
export const ModalContext = createContext<ContextData>(null as unknown as ContextData);

export const ModalProvider: FunctionComponent<ContextData> = ({ children, open, close }) => {
  const value = useMemo(() => ({ open, close }), [open, close]);
  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);

export const useTypedModal = (component: any, title: string, args: unknown) => {
  const { open, close } = useModalContext();
  const payload = {
    component,
    options: {
      title
    },
    args
  };
  return {
    open: () => open(payload),
    close
  };
};
