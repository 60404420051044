import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { hideBoxShadowOnPrint } from '@/utils/styles';

type AvailableColors = 'green' | 'blue';

const Flex = styled.div`
  display: flex;
`;

const Container = styled.div<{ margin?: string }>`
  margin: ${props => props.margin};
`;

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.blue};
  font-size: 18px;
  font-weight: bold;
`;

const TitleIcon = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px; /* Very small shift down for text align */
  opacity: 0.6;
  & svg {
    width: 16px;
    height: 16px;
  }
`;

const TitleText = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
  ${TitleIcon} + & {
    padding-left: 16px;
  }
`;

const Content = styled.div<{ padding?: string, color?: AvailableColors }>`
  background-color: ${props => props.theme.white};
  padding: ${props => props.padding ? props.padding : '32px'};
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
  ${hideBoxShadowOnPrint}
  border-top: 4px solid;
  border-top-color: ${props =>
    (props.color === 'green' && props.theme.lightGreen) ||
    (props.theme.maritime2)};
`;

const ButtonWrapper = styled.div`
  padding-right: 20px;
  align-self: center;
`;

const SubTitle = styled.div`
  color: ${props => props.theme.blue};
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: -22px; //hack to avoid unaligning border-top for content
`;

interface ModernPaneProps {
  title?: string | JSX.Element;
  icon?: JSX.Element;
  margin?: string;
  padding?: string;
  button?: JSX.Element;
  subTitle?: string;
  color?: AvailableColors;
}
export const ModernPane: FunctionComponent<ModernPaneProps> = (
  { title, icon, margin, padding, children, button: Button, subTitle, color = 'blue' }
) => (
  <Container margin={margin}>
    <TitleArea>
      <Flex>
        {icon && <TitleIcon>{icon}</TitleIcon>}
        <TitleText>{title}</TitleText>
      </Flex>
      <Flex>
        {Button && <ButtonWrapper>{Button}</ButtonWrapper>}
      </Flex>
    </TitleArea>
    {subTitle && <SubTitle>{subTitle}</SubTitle>}
    <Content padding={padding} color={color}>
      {children}
    </Content>
  </Container>
);
