import { AddCompanyProgress } from '@/enrollment/core/AddCompanyProgress';
import { NextButton } from '@/enrollment/core/NextButton';
import { TopSection } from '@/enrollment/core/TopSection';
import { getExistingCompanyIdAsync } from '@/services/companiesService';
import { useExternalCompanies } from '@/services/externalCompaniesService';
import { ExternalCompany, Guid } from '@/types';
import React, { useEffect, useState } from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { CompanySearch } from './core/CompanySearch';
import { SearchResults } from './core/SearchResults';

const message = 'Please select a company';

export const SelectCompanyStep = () => {
  const [submitError, setSubmitError] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [selectedType, setSelectedType] = useState<string | undefined>('AA');
  const [selectedCompanyId, setSelectedCompanyId] = useState<Guid | undefined>(undefined);
  const [validationMessage, setValidationMessage] = useState<string | undefined>(undefined);
  const { data: companies, error, isValidating } = useExternalCompanies(selectedType, searchTerm);
  const { path } = useRouteMatch();
  const [redirectCompanyId, setRedirectCompanyId] = useState<number | undefined | string>(undefined);

  const selectCompany = (newId?: string) => {
    const value = newId === selectedCompanyId ? undefined : newId;
    setSelectedCompanyId(value);
  };

  useEffect(() => {
    setSelectedCompanyId(undefined);
  }, [searchTerm, selectedType]);

  useEffect(() => {
    setValidationMessage(undefined);
  }, [searchTerm, selectedType, selectedCompanyId]);

  if (redirectCompanyId) {
    return <Redirect to={`${path}/${redirectCompanyId}`} />;
  }

  if (error || submitError) {
    throw new Error(error || submitError);
  }

  const submit = async () => {
    if (!selectedCompanyId) {
      setValidationMessage(message);
      return;
    }
    const company = companies?.find(c => c.id === selectedCompanyId) as ExternalCompany;
    try {
      const result = await getExistingCompanyIdAsync(selectedCompanyId);
      const route = result ? `add-portal/${company.id}` : `enroll/${company.id}`;
      setRedirectCompanyId(route);
    } catch (err) {
      setSubmitError(err);
    }
  };
  return (
    <div>
      <TopSection>
        <AddCompanyProgress currentStep={0} />
        <NextButton submit={submit} validationMessage={validationMessage} />
      </TopSection>
      <CompanySearch
        onReset={() => setSearchTerm(undefined)}
        onSearch={setSearchTerm}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />
      <SearchResults
        isLoading={isValidating}
        searchTerm={searchTerm}
        companies={companies}
        selectedCompanyId={selectedCompanyId}
        setSelectedCompanyId={selectCompany}
      />
    </div>
  );
};
