import { ExternalUser, RecipientStructure } from '@/types';

export const companyTitle = (user: ExternalUser) => {
  const companiesText = user.company ?? '';
  if (user.additionalCompanies) {
    const otherCompaniesText = user.additionalCompanies.map(company => ` • ${company.name}`).join('');
    return `${companiesText}${otherCompaniesText}`;
  }
  return companiesText;
};

export const portalNames = (recipients: RecipientStructure) => {
  const { applications } = recipients;
  let name = '';
  applications.forEach((element, index) => {
    if (element.selected) {
      if (name.length > 0) name += ', ';
      name += element.displayName;
    }
  });
  return name;
};

export const roles = (recipients: RecipientStructure, ellipsis: boolean) => {
  const { applications } = recipients;
  let rolesText = '';
  applications.forEach(element => {
    if (element.selected) {
      element.roles.forEach(role => {
        if (role.selected) {
          if (rolesText.length > 0) rolesText += ', ';
          const name = role.displayName ?? role.name;
          rolesText += name;
        }
      });
    }
  });
  if (ellipsis && rolesText.length > 40) {
    return `${rolesText.substring(0, 37)}...`;
  }

  return rolesText;
};
