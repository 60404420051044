import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { theme } from '@/utils/theme';

const StyledReactTooltip = styled(ReactTooltip)`
  &.marineBlueTooltip {
    padding: 8px 12px;
    &.show {
      opacity: 1;
      max-width: 230px;
    }
  }
`;

interface RoleTooltipProps {
  id: string;
}

export const RoleTooltip = ({ id }: RoleTooltipProps) => (
  <StyledReactTooltip
    id={id}
    effect="solid"
    backgroundColor={theme.marineBlue}
    className="marineBlueTooltip"
  />
);
